import { Flex, Text } from '@chakra-ui/react';
import LegendDot from '../../assets/icons/LegendDot';

const ChipPercantege = ({name, percentage, index}) => {

  const getLegendDotColor = (index) => {
    const colors = ['#FA8638', '#676666', '#FFCC95'];
    return colors[index % colors.length];
  };

  return (
    <Flex alignItems="center" mr={'10px'}>
      <LegendDot fill={getLegendDotColor(index)} />
      <Text fontSize="12px" fontWeight={'700'} color="#475467" ml={'5px'}>
        {`${name} (${percentage?.toFixed(1)})%`}
      </Text>
    </Flex>
  );
};

export default ChipPercantege;
