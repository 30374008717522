import {
  Box,
  Flex,
  Select,
  Text,
  useToast,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
} from '@chakra-ui/react';
import Twitter from 'assets/icons/Twitter';
import Instagram from 'assets/icons/Instagram';
import TikTok from 'assets/icons/TikTok';
import YouTube from 'assets/icons/YouTube';
import Facebook from 'assets/icons/Facebook';
import Linkedin from 'assets/icons/LinkedIn';
import Reddit from 'assets/icons/Reddit';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CustomSelect from 'components/peoplePage/CustomSelect';
import countriesData from './country.json';
import analytics from '../../../../segmentTracking';
import { Switch } from '../../../../components/Switch/index';

const API_URL = process.env.REACT_APP_API_URL;

let socialMediaList = [
  {
    name: 'X (formerly Twitter)',
    type: 1,
    icon: Twitter,
    nameCompare: 'Twitter',
  },
  {
    name: 'Instagram',
    type: 1,
    icon: Instagram,
    nameCompare: 'Instagram',
  },
  {
    name: 'Tik Tok',
    type: 1,
    icon: TikTok,
    nameCompare: 'TikTok',
  },
  {
    name: 'YouTube shorts',
    type: 1,
    icon: YouTube,
    nameCompare: 'YouTube',
  },
  {
    name: 'Facebook',
    type: 0,
    icon: Facebook,
    nameCompare: 'Facebook',
  },
  {
    name: 'LinkedIn',
    type: 0,
    icon: Linkedin,
    nameCompare: 'Linkedin',
  },
  {
    name: 'Reddit',
    type: 0,
    icon: Reddit,
    nameCompare: 'Reddit',
  },
];

const RestrictedTopicsPlatforms = () => {
  const toast = useToast();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [countryName, setCountryName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [switchStates, setSwitchStates] = useState({});
  const [loadingStates, setLoadingStates] = useState(false);
  const [restrictedTopics, setRestrictedTopics] = useState([]);
  const [restrictedPlatforms, setRestrictedPlatforms] = useState([]);
  const token = localStorage.getItem('token');
  const [customer, setCustomers] = useState([]);
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));

  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchCountries = async () => {
    try {
      const updatedCountries = countriesData.map((country) => ({
        display: country?.label, // Change Name to Display
        value: `${country?.abbreviation}country${country?.fullName}`, // Change isoCode to value
      }));

      setCountries(updatedCountries);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchRestrictedItems = async () => {
    console.log('selectedCustomer', selectedCustomer);

    try {
      const topicResponse = await axios.get(
        `${API_URL}/dashboard/restrictions/getGlobalRestrictedTopicAndPlatforms/topic?country=${countryName}&state=${selectedState}&institute=${selectedCustomer}`,
        headers,
      );

      const platformResponse = await axios.get(
        `${API_URL}/dashboard/restrictions/getGlobalRestrictedTopicAndPlatforms/platform?country=${countryName}&state=${selectedState}&institute=${selectedCustomer}`,
        headers,
      );

      const topicData = topicResponse.data.data;
      const platformData = platformResponse.data.data;
      setRestrictedTopics(topicData);
      setRestrictedPlatforms(platformData);
    } catch (error) {
      console.error('Error fetching restricted items:', error);
      toast({
        title: 'Error',
        description: error?.response?.data?.message || 'State not found.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/dashboard/institute/getInstitute?country=${countryName}&state=${selectedState}`,
        headers,
      );
      const data = response.data.data;
      const updatedCustomers = data?.map((institute) => ({
        display: institute?.name, // Change Name to Display
        value: institute?._id, // Change isoCode to value
      }));
      console.log('updatedCustomers', updatedCustomers);

      setCustomers(updatedCustomers);
    } catch (error) {
      console.error('Error fetching customers:', error);
      toast({
        title: 'Error',
        description: 'Customers not found.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    fetchRestrictedItems();
  }, [selectedCountry, selectedState, selectedCustomer, switchStates]);

  useEffect(() => {
    fetchCustomers();
  }, [selectedCountry, selectedState]);

  const handleSwitchChange = async (id, disabledState, tab) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));
    if (!token) {
      console.error('Token not found');
      return;
    }

    // Determine the current switch state
    const isSwitchOn = switchStates[id];

    try {
      // if (tab === 'platform') {
      const url = `${API_URL}/dashboard/restrictions/${
        disabledState ? 'unrestrictTopicPlatform' : 'restrictTopicPlatform'
      }`;
      const body = {
        country: countryName,
        state: selectedState,
        institute: selectedCustomer,
        itemId: id,
        type: tab,
        restricted: !disabledState,
      };

      let response;

      if (disabledState) {
        response = await axios.patch(url, body, headers);
      } else {
        response = await axios.post(url, body, headers);
      }

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      // Add event tracking for switch toggle
      const eventType = tab === 'topic' ? 'category' : 'platform';
      const itemName = tab === 'topic' 
      ? restrictedTopics.find(t => t._id === id)?.title 
      : restrictedPlatforms.find(platform => platform._id === id)?.name;

      analytics.track(`settings:globaldata:${eventType}toggled`, {
        [eventType]: itemName,
        action: disabledState ? 'enabled' : 'disabled',
        userRole: currentUser?.role,
        email: currentUser?.email,
      });

      // Update the switch state
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [id]: !isSwitchOn,
      }));

      const word = tab.charAt(0).toUpperCase() + tab.slice(1);

      // toast({
      //   title: `Successful`,
      //   description: !isSwitchOn ? `${word} Removed` : `${word} Added`,
      //   status: 'success',
      //   duration: 3000,
      //   isClosable: true,
      // });
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
    } catch (error) {
      console.error(
        'Error:',
        error.response ? error.response.data : error.message,
      );
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [id]: !prevStates[id],
      }));
    } finally {
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleCountry = (value) => {
    let countryCodeAndName = value?.split('country');
    const country = countriesData.find(
      (c) => c?.abbreviation === countryCodeAndName[0],
    );

     // Add event tracking for country selection
     analytics.track('settings:globaldata:countryselected', {
      country: countryCodeAndName?.at(-1),
      userRole: currentUser?.role,
      email: currentUser?.email,
    });

    setSelectedState('');
    setSelectedCustomer('');
    setCountryName(countryCodeAndName?.at(-1));
    setSelectedCountry(value);

    const updatedStates = country.states.map((state) => ({
      display: state?.label, // Change Name to Display
      value: state?.label, // Change isoCode to value
    }));
    setStates(country ? updatedStates : []);
  };

  const handleStateChange = (state) => {
    // Add event tracking for state selection
    analytics.track('settings:globaldata:stateselected', {
      email: currentUser?.email,
      userRole: currentUser?.role,
      state: state,
    });
    setSelectedState(state);
  };

  const handleCustomerChange = (customer) => {
    // Add event tracking for customer selection
    analytics.track('settings:globaldata:customerselected', {
      customer: customer,
      email: currentUser?.email,
      userRole: currentUser?.role,
    });
    setSelectedCustomer(customer);
  };

  // Add tracking for tab changes
  const handleTabChange = (index) => {
    const tabName = index === 0 ? 'Categories' : 'Platforms';
    analytics.track(`settings:globaldata:selected${tabName}`, {
      tab: tabName.toLowerCase(),
      userRole: currentUser?.role,
      email: currentUser?.email,
    });
  };

  return (
    <Box width={'65%'} margin={'24px auto 48px'}>
      <Box>
        <Text fontSize={'20px'} fontWeight={'700'}>
          Media Access
        </Text>
        <Flex flexDir={'column'} margin={'10px 0'}>
          <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box flexBasis={'55%'}>
              <Text
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'400'}
                color={'#757575'}
                pt={'4px'}
              >
                Edit global data settings for a country or state
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Flex pt={'8px'} gap="4">
        <Box flexBasis={'15%'}>
          <CustomSelect
            options={countries}
            placeholder="All Countries"
            width="150px"
            value={selectedCountry}
            onChange={(newCountry) => handleCountry(newCountry)}
          />
        </Box>
        <Box flexBasis={'15%'}>
          <CustomSelect
            options={states}
            placeholder="All States"
            width="150px"
            value={selectedState}
            disbaled={!selectedCountry || undefined ? true : false}
            onChange={(e) => handleStateChange(e)}
            opacity={!selectedCountry || undefined ? '0.3' : '1'}
            backgroundColor={!selectedCountry || undefined ? '#F5F5F5' : 'white'}
          />
        </Box>
        <Box flexBasis={'15%'}>
          <CustomSelect
            options={customer}
            placeholder="All customers"
            width="150px"
            value={selectedCustomer}
            onChange={(customer) => handleCustomerChange(customer)}
          />
        </Box>
      </Flex>
      <Flex flexDir={'column'} margin={'20px 0'}>
        <Box mt={6}>
          <Tabs variant="enclosed" onChange={handleTabChange}>
            <TabList>
              <Tab>Categories</Tab>
              <Tab>Platforms</Tab>
            </TabList>

            <TabPanels>
              {/* Topics Tab */}
              <TabPanel>
                {restrictedTopics.length > 0 ? (
                  <Flex flexDir={'column'} margin={'10px 0'}>
                    {restrictedTopics.map((topic) => {
                      // Check if foundMedia exists and get its disabled state
                      const disabledState = topic ? topic.disabled : false;
                      const _id = topic ? topic._id : null;
                      const isLoading = loadingStates[_id] || false;

                      return (
                        <Flex
                          key={topic?._id}
                          flexDir={'column'}
                          paddingBottom={'12px'}
                          paddingTop={'12px'}
                        >
                          <Flex
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                          >
                            <Flex
                              justifyContent={'space-between'}
                              alignItems={'flex-start'}
                              opacity={!disabledState ? '1' : '0.3'}
                            >
                              {/* Render the icon */}
                              <Text
                                fontSize={'14px'}
                                lineHeight={'20px'}
                                fontWeight={'700'}
                                color={'#222222'}
                                pt={'4px'}
                                marginLeft="10px"
                              >
                                {topic?.title}
                              </Text>
                            </Flex>
                            <Box
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {isLoading ? (
                                <Flex
                                  height={'22px'}
                                  width={'44px'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                >
                                  <Spinner size={'sm'} color="#EF6506" />
                                </Flex>
                              ) : (
                                <Switch
                                  id={_id}
                                  checked={!disabledState || false}
                                  onCheckedChange={() =>
                                    handleSwitchChange(
                                      _id,
                                      disabledState,
                                      'topic',
                                    )
                                  }
                                  // sx={{
                                  //   '& .chakra-switch__track': {
                                  //     backgroundColor: '#C2C2C2',
                                  //   },
                                  //   '& .chakra-switch__track[data-checked]': {
                                  //     backgroundColor: '#EF6506',
                                  //   },
                                  // }}
                                />
                              )}
                            </Box>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                ) : (
                  <Text>No restricted topics for the selected state.</Text>
                )}
              </TabPanel>

              {/* Platforms Tab */}
              <TabPanel>
                {restrictedPlatforms.length > 0 ? (
                  <Flex flexDir={'column'} margin={'10px 0'}>
                    {socialMediaList.map((media) => {
                      const foundMedia = restrictedPlatforms.find(
                        (item) => item.name === media.nameCompare,
                      );

                      // Check if foundMedia exists and get its disabled state
                      const disabledState = foundMedia
                        ? foundMedia.disabled
                        : false;
                      const _id = foundMedia ? foundMedia._id : null;
                      const isLoading = loadingStates[_id] || false;

                      return (
                        <Flex
                          key={media?.id}
                          flexDir={'column'}
                          paddingBottom={'12px'}
                          paddingTop={'12px'}
                          borderBottom={
                            media.name === 'Reddit'
                              ? '0px'
                              : '1px solid #E7E7E7'
                          }
                          opacity={media.type === 1 ? '1' : '0.3'}
                        >
                          <Flex
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                          >
                            <Flex
                              justifyContent={'space-between'}
                              alignItems={'flex-start'}
                              opacity={!disabledState ? '1' : '0.3'}
                            >
                              {media.icon && (
                                <Box
                                  as={media.icon}
                                  fill={'#FFFDFB'}
                                  mr="8px"
                                />
                              )}{' '}
                              {/* Render the icon */}
                              <Text
                                fontSize={'14px'}
                                lineHeight={'20px'}
                                fontWeight={'700'}
                                color={'#222222'}
                                pt={'4px'}
                                marginLeft="10px"
                              >
                                {media.name}
                              </Text>
                            </Flex>
                            <Box
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {isLoading ? (
                                <Flex
                                  height={'22px'}
                                  width={'44px'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                >
                                  <Spinner size={'sm'} color="#EF6506" />
                                </Flex>
                              ) : (
                                <Switch
                                  id={_id}
                                  checked={
                                    (!disabledState && media.type === 1) ||
                                    false
                                  }
                                  onCheckedChange={() =>
                                    handleSwitchChange(
                                      _id,
                                      disabledState,
                                      'platform',
                                    )
                                  }
                                  disabled={media.type === 1 ? false : true}
                                  // sx={{
                                  //   '& .chakra-switch__track': {
                                  //     backgroundColor: '#C2C2C2',
                                  //   },
                                  //   '& .chakra-switch__track[data-checked]': {
                                  //     backgroundColor: '#EF6506',
                                  //   },
                                  // }}
                                />
                              )}
                            </Box>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                ) : (
                  <Text>No restricted platforms for the selected state.</Text>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Box>
  );
};

export default RestrictedTopicsPlatforms;
