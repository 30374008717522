import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';

const DeleteModal = ({ onClose, isOpen, handleDelete }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay bg="#53535399" />
      <ModalContent
        bg={bgColor}
        borderColor={borderColor}
        borderWidth="1px"
        borderRadius={'12px'}
        alignSelf={'center'}
      >
        <ModalCloseButton />
        <ModalBody>
          <p>Are you sure you want to delete?</p>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={3}>
            <Button
              variant="outline"
              onClick={onClose}
              borderRadius={'8px'}
              padding={'6px 12px'}
            >
              Cancel
            </Button>
            <Button
              bg={'#EF6506'}
              color={'white'}
              onClick={() => {
                handleDelete();
                onClose();
              }}
              borderRadius={'8px'}
              borderColor={'#AAAAAA'}
              height={'36px'}
              fontSize={'14px'}
              fontWeight={'700'}
              _hover={'none'}
              padding={'6px 12px'}
            >
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
