import React from 'react';
import { Avatar, Flex, Text } from '@chakra-ui/react';

const Chip = ({ variant, label, hasAvatar }) => {
  const variants = {
    primary: {
      bg: '#EF65063D',
      border: '1px solid #EF6506',
      color: '#EF6506',
    },
    secondary: {
      bg: '#F0A41329',
      border: '1px solid #F0A413',
      color: '#F0A413',
    },
    tertiary: {
      bg: '#AAAAAA3D',
      border: '1px solid #AAAAAA',
      color: '#676666',
    },
  };

  const style = variants[variant] || variants.primary;
  // const [isLargerThan850] = useMediaQuery('(min-width: 850px)');

  return (
    <Flex
      height="26px"
      padding="12px"
      justify="center"
      alignItems="center"
      bg={style.bg}
      border={style.border}
      borderRadius="100px"
      fontSize={['10px', '12px']}
      fontWeight="700"
      color={style.color}
    >
      {hasAvatar && (
        // <Image
        //   height={'16px'}
        //   width={'16px'}
        //   src={Avatar}
        //   marginRight={'4px'}
        // />
        <Avatar
          src="https://bit.ly/broken-link"
          height={'16px'}
          width={'16px'}
          marginRight={'4px'}
          bg={'#AAAAAA'}
          borderRadius={'50%'}
        />
      )}
      <Text fontFamily={'"DM Sans", sans-serif'} fontWeight={'700'}>
        {label}
      </Text>
    </Flex>
  );
};

export default Chip;
