import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Dot,
} from 'recharts';
import { useGate } from 'statsig-react';
import { debounce } from 'lodash'; // Optional: lodash for debouncing
const CustomTooltip = ({ active, payload, label, barLables, name }) => {
  if (active && payload && payload?.length) {
    const getLabel = (dataKey) => barLables[dataKey] || dataKey;
    return (
      <Box bg="white" p={2} border="1px solid #E7E7E7" borderRadius="md">
        <Text fontSize={'12px'} fontWeight="bold">
          {label}
        </Text>
        {payload?.map((entry, index) => {
          let value = entry.value;
          if (!name) {
            if (Math.round(entry.value) < 60) {
              value = `${entry.value.toFixed()}s`;
            } else if (Math.round(entry.value) < 3600) {
              value = `${(entry.value / 60).toFixed()}m`;
            } else {
              const hours = Math.floor(value / 3600);
              const minutes = Math.floor((value % 3600) / 60);
              value = `${hours}hr${hours > 1 ? 's' : ''} ${
                minutes > 0 ? `${minutes}min${minutes > 1 ? 's' : ''}` : ''
              }`;
            }
          }
          return (
            <Text key={`item-${index}`} fontSize={'12px'} color={entry?.color}>
              {getLabel(entry.dataKey)}: {value}
            </Text>
          );
        })}
      </Box>
    );
  }
  return null;
};
const LineBarChart = ({
  data,
  isBarBackground = false,
  yAxisLabel,
  BarContainerHeight = '230px',
  yAxisComponentLabel,
  barLables = {},
  name,
  colors = ['#EF6506', '#F0A413', '#AAAAAA'],
}) => {
  const [chartData, setChartData] = useState([]);
  const { value: barchartsDots } = useGate('barcharts_dots');
  const handleDataUpdate = debounce((updatedData) => {
    setChartData(updatedData);
  }, 10);
  useEffect(() => {
    if (data?.length) {
      handleDataUpdate(data);
    }
  }, [data]); // eslint-disable-line
  const isNumeric =
    typeof chartData[0]?.[Object?.keys(chartData[0])[1]] === 'number';
  const categoricalMapping = {
    Low: 1,
    Medium: 2,
    High: 3,
  };

  let convertedData = !isNumeric
    ? chartData?.map((item) => {
        const newItem = { ...item };
        Object.keys(item)?.forEach((key) => {
          if (key !== 'xAxis') {
            newItem[key] = categoricalMapping[item[key]] || item[key];
          }
        });
        return newItem;
      })
    : chartData;

  // Get the keys for the areas (excluding 'xAxis')
  const areaKeys = Object.keys(chartData[0] || {})?.filter(
    (key) => key !== 'xAxis',
  );
  // // Define colors for each area
  // const colors = ['#EF6506', '#F0A413', '#AAAAAA'];

  function customYAxisTick(value) {
    if (value < 60) {
      return `${value}s`; // show seconds
    } else if (value < 3600) {
      let result = value / 60;
      let remainder = result % 1;
      if (remainder < 0.51) {
        result = Math.floor(result);
      } else {
        result = Math.ceil(result);
      }
      return `${(value / 60).toFixed(1)}m`; // show minutes with 1 decimal
    } else {
      return `${(value / 3600).toFixed()}h`;
    }
  }

  const customTick = (tick) => {
    if (isNumeric) {
      return name ? tick : customYAxisTick(tick);
    }
    return Object.keys(categoricalMapping).find(
      (key) => categoricalMapping[key] === tick,
    );
  };

  return (
    <Box height={BarContainerHeight} mt={2}>
      <Text
        fontSize="12px"
        fontWeight={'400'}
        color="#676666"
        mt={1}
        mb={5}
        ml={3}
      >
        {yAxisComponentLabel}
      </Text>
      <ResponsiveContainer
        width="100%"
        height="100%"
        className={'engagementGraph'}
      >
        <AreaChart
          data={convertedData}
          margin={{ top: 10, right: 10, left: 0, bottom: 10 }}
        >
          {isBarBackground && (
            <defs>
              {colors.map((color, index) => (
                <linearGradient
                  key={`color${index + 1}`}
                  id={`color${index + 1}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor={color} stopOpacity={0.3} />
                  <stop offset="95%" stopColor={color} stopOpacity={0} />
                </linearGradient>
              ))}
            </defs>
          )}
          <XAxis
            dataKey="xAxis"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#475467', fontSize: 12 }}
            padding={{ right: 18, left: 10 }}
            tickMargin={15}
            height={30}
            interval={0}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{
              fill: '#475467',
              fontSize: 12,
              textAnchor: 'start',
              dx: -38,
            }}
            domain={isNumeric ? ['auto', 'auto'] : [1, 3]}
            ticks={isNumeric ? undefined : [1, 2, 3]}
            tickFormatter={customTick}
          />
          <CartesianGrid
            strokeDasharray="2 6"
            vertical={false}
            stroke="#E7E7E7"
          />
          <Tooltip
            content={<CustomTooltip name={name} barLables={barLables} />}
          />
          {areaKeys.map((key, index) => {
            return (
              <Area
                key={key}
                type="monotone"
                dataKey={key}
                stroke={
                  areaKeys.length === 2 && index === areaKeys.length - 1
                    ? colors[(index + 1) % colors.length]
                    : colors[index % colors.length]
                }
                strokeWidth={2}
                fillOpacity={1}
                fill={
                  isBarBackground ? `url(#color${index + 1})` : 'transparent'
                }
                dot={barchartsDots && <Dot />}
                name={barLables[key] || key}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default LineBarChart;
