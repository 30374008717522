import './assets/css/App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { StatsigProvider } from 'statsig-react';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from './theme/theme';
import { useEffect, useState } from 'react';
import { ClerkProvider, useAuth, useClerk } from '@clerk/clerk-react';
import SignIn from 'views/auth/signIn';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { handleSessionTimeout } from 'utils/sessionManagementUtils';

// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

function ProtectedRoute({ children }) {
  const { isLoaded } = useAuth();
  const clerk = useClerk();
  const location = useLocation();

  useEffect(() => {
    const checkSessionValidity = async () => {
      try {
        // Check if the session is still valid
        const session = await clerk.session;

        session?.status === 'expired' && handleSessionTimeout('sessionExpired');

        // Optional: Check token expiration
        const tokenExpiresAt = session?.lastActiveAt;
        const currentTime = new Date();

        // If token is close to expiration (e.g., within 5 minutes)
        tokenExpiresAt &&
          currentTime.getTime() - tokenExpiresAt.getTime() > 5 * 60 * 1000 &&
          handleSessionTimeout('sessionExpired2');
      } catch (error) {
        console.error('Session validation error:', error);
        return handleSessionTimeout('sessionExpired3');
      }
    };

    // Check session every 5 minutes
    const intervalId = setInterval(checkSessionValidity, 5 * 60 * 1000);

    // Initial check
    checkSessionValidity();

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [clerk, location]);

  if (!isLoaded) {
    return <div></div>; // Or a proper loading component
  }

  // if (!isSignedIn) {
  //   return <SignIn />;
  // }

  return children;
}

export default function App() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  return (
    <Provider store={store}>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <StatsigProvider
          sdkKey={'client-pkcwnlJRilIrnP3dQxX5VJeRrjWYoWHc5OK7nyG4s0N'}
          waitForInitialization={true}
          options={{
            environment: {
              tier: 'staging',
            },
          }}
        >
          <ChakraProvider theme={currentTheme}>
            <Routes>
              <Route path="auth/*" element={<AuthLayout />} />
              <Route
                path="admin/*"
                element={
                  <ProtectedRoute>
                    <AdminLayout
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="rtl/*"
                element={
                  <ProtectedRoute>
                    <RTLLayout
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Navigate to="/admin" replace />
                  </ProtectedRoute>
                }
              /> */}
              <Route path="*" element={<SignIn />} />
            </Routes>
          </ChakraProvider>
        </StatsigProvider>
      </ClerkProvider>
    </Provider>
  );
}
