import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import CheckIcon from 'assets/icons/Check';
import React, { useState } from 'react';

const CustomSelect = ({ options, placeholder, width, value, onChange , downIconFill , disbaled , backgroundColor}) => {
  const [isOpen, setIsOpen] = useState(false);

const handleChange = (option) => {
  // Check if the option is valid before accessing its properties
  if (option && option.value !== undefined) {
    if (typeof onChange === 'function') {
      onChange(option?.value);
    }
    setIsOpen(false);
  } else {
    console.error('Invalid option:', option);
  }
};

  const getDisplayValue = () => {
    const selectedOption = options.find((option) => option?.value === value);
    return selectedOption ? selectedOption.display : placeholder;
  };

  return (
    <Popover
      placement="bottom-start"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <PopoverTrigger>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width={width}
          border="1px solid #E7E7E7"
          borderRadius="8px"
          px={3}
          py={2}
          height={'32px'}
          cursor="pointer"
          _hover={!disbaled && { borderColor: '#EF6506' }}
          onClick={() => !disbaled && setIsOpen(!isOpen)}
          _disabled={disbaled}
          // backgroundColor={'#E7E7E7'}
          opacity={disbaled ? 0.5 : 1}
        >
          <Text fontSize="sm">{getDisplayValue()}</Text>
          <ChevronDownIcon fill={downIconFill} />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        width={width}
        boxShadow="md"
        border="1px solid #EF6506"
        borderRadius="8px"
        _focus={{ outline: 'none', boxShadow: 'none' }}
      >
        <PopoverBody p={0}>
          <VStack align="stretch" spacing={0} maxH={500} overflowY={'auto'}>
            {options.map((option) => (
              <Box
                key={option?.value}
                px={3}
                py={2}
                _hover={{ bg: '#FFF8F3' }}
                cursor="pointer"
                onClick={() => handleChange(option)}
                _selected={{ bg: '#FFF8F3' }}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                borderRadius={'8px'}
              >
                <Text fontSize="sm">{option?.display}</Text>
                {option?.value === value && (
                  <CheckIcon color="#EF6506" /> // Show the tick icon if this option is selected
                )}
              </Box>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CustomSelect;
