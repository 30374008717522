import React from 'react';

function EyeIcon({ fill = "#222222", ...prop }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_763_13091)">
        <g clipPath="url(#clip1_763_13091)">
          <path
            d="M10 5.41667C13.1583 5.41667 15.975 7.19167 17.35 10C15.975 12.8083 13.1667 14.5833 10 14.5833C6.83333 14.5833 4.025 12.8083 2.65 10C4.025 7.19167 6.84166 5.41667 10 5.41667ZM10 3.75C5.83333 3.75 2.27499 6.34167 0.833328 10C2.27499 13.6583 5.83333 16.25 10 16.25C14.1667 16.25 17.725 13.6583 19.1667 10C17.725 6.34167 14.1667 3.75 10 3.75ZM10 7.91667C11.15 7.91667 12.0833 8.85 12.0833 10C12.0833 11.15 11.15 12.0833 10 12.0833C8.85 12.0833 7.91666 11.15 7.91666 10C7.91666 8.85 8.85 7.91667 10 7.91667ZM10 6.25C7.93333 6.25 6.25 7.93333 6.25 10C6.25 12.0667 7.93333 13.75 10 13.75C12.0667 13.75 13.75 12.0667 13.75 10C13.75 7.93333 12.0667 6.25 10 6.25Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_763_13091">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_763_13091">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EyeIcon;
