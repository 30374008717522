import * as React from 'react'
import * as SwitchPrimitives from '@radix-ui/react-switch'

import { cn } from '../../utils/helper'

interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  checkedColor?: string
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, checkedColor = 'bg-orange-500', ...props }, ref) => {
  // Explicitly separate the Tailwind classes for checked and unchecked states
  const baseClasses = cn(
    'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors',
    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background',
    'disabled:cursor-not-allowed disabled:opacity-50'
  )

  const uncheckedClasses = 'bg-gray-200'
  const checkedColorClass = checkedColor

  return (
    <SwitchPrimitives.Root
      className={cn(
        baseClasses,
        props.checked || props.defaultChecked ? checkedColorClass : uncheckedClasses,
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none block h-5 w-5 rounded-full bg-white shadow-lg ring-0 transition-transform',
          props.checked || props.defaultChecked ? 'translate-x-5' : 'translate-x-0'
        )}
      />
    </SwitchPrimitives.Root>
  )
})

Switch.displayName = 'Switch'

export { Switch }
