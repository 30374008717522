import React, { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  HStack,
  Button,
  Avatar,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import VerifiedIcon from 'assets/icons/verifiedIcon';
import CancelIcon from 'assets/icons/deletedIcon';
import RightOutlinedArrow from 'assets/icons/RightOutlinedArrow';
import LeftOutlineArrow from 'assets/icons/LeftOutlineArrow';
import CustomSelect from 'components/peoplePage/CustomSelect';
import analytics from '../../../../segmentTracking';

const columnHelper = createColumnHelper();

const tableDataLimit = [
  { value: 25, display: 25 },
  { value: 50, display: 50 },
  { value: 100, display: 100 },
];

export default function CheckTable({
  visibleColumns,
  tableData,
  setShowProfile,
  setSingleUserData,
  totalUsersLength,
  currentPage,
  setCurrentPage,
  setSelectedRows,
  selectedRows,
  setItemsPerPage,
  itemsPerPage,
  isLoading,
}) {
  const [sorting, setSorting] = useState([]);
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [data, setData] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [userType, setUserType] = useState(itemsPerPage);
  const toast = useToast();
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));

  useEffect(() => {
    if (Array.isArray(tableData) && !isLoading) {
      if (
        tableData.length === 0 ||
        tableData[0]?.message !== 'No records found'
      ) {
        setData(tableData);
      }
    }
  }, [tableData, isLoading]);

  useEffect(() => {
    setUserType(itemsPerPage);
  }, [itemsPerPage]);

  const toggleAllRows = (checked) => {
    if (checked) {
      const visibleData = getCurrentPageData();
      const allSelectedUsers = visibleData
        .map((row) => row.original)
        .filter((item) => item.email)
        .map((item) => ({
          email: item.email,
          clerkUserId: item.clerkUserId,
        }));
      setSelectedRows(allSelectedUsers);
    } else {
      setSelectedRows([]);
    }
    setIsAllSelected(checked);
  };

  const columns = [
    visibleColumns?.name &&
    columnHelper.accessor('select', {
      header: ({ table }) => (
        <Box onClick={(e) => e.stopPropagation()}>
          <Checkbox
            colorScheme="orange"
            borderColor="#AAAAAA"
            isChecked={isAllSelected}
            onChange={(e) => {
              setIsAllSelected(!isAllSelected);
              toggleAllRows(e.target.checked);
            }}
          />
        </Box>
      ),
      cell: ({ row }) => {
        return (
          <Checkbox
            colorScheme="orange"
            borderColor={'#AAAAAA'}
            isChecked={selectedRows.some((item) => {
              if (item?.email) {
                return item.email === row.original.email;
              }
              return item.phone === row.original.phoneNumber;
            })}
            onChange={(e) => {
              const isChecked = e.target.checked;
              const email = row.original.email;
              const phone = row.original.phoneNumber;
              if (isChecked && !email && !phone) {
                toast({
                  title: 'Error',
                  description: "User doesn't have email id or phone",
                  variant: 'solid',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
              } else {
                const newData = data.map((item) =>
                  item.id === row.original.id
                    ? { ...item, isSelected: isChecked }
                    : item,
                );
                setData(newData);
                setSelectedRows((prev) => {
                  if (isChecked) {
                    return [
                      ...prev,
                      {
                        email: email,
                        phone: phone,
                        clerkUserId: row.original.clerkUserId,
                      },
                    ];
                  } else {
                    return prev.filter((item) => {
                      if (item?.email) {
                        return item.email !== email;
                      }
                      return item.phone !== phone;
                    });
                  }
                });
              }
            }}
            onClick={(e) => e.stopPropagation()}
          />
        );
      },
      size: 50,
    }),
    visibleColumns?.name &&
    columnHelper.accessor('name', {
      header: () => (
        <Flex align="center">
          <Text
            fontSize="14px"
            fontWeight="700"
            paddingLeft={'16px'}
            style={{ borderLeft: '.5px solid #E7E7E7' }}
            minW={'120px'}
            maxW={'135px'}
          >
            NAME
          </Text>
        </Flex>
      ),
      cell: (info) => (
        <Flex align="center">
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Flex
              alignItems={'center'}
              minW={'120px'}
              maxW={'135px'}
              height={'32px'}
            >
              {info.getValue() && (
                <Avatar
                  src="https://bit.ly/broken-link"
                  height={'32px'}
                  width={'32px'}
                  boxSize="32px"
                  borderRadius="full"
                  mr="3"
                  ml="4"
                />
              )}
              <Text fontSize="14px" fontWeight="500" color={'#222222'}>
                {info.getValue()}
              </Text>
            </Flex>
          </Box>
        </Flex>
      ),
    }),
    visibleColumns?.email &&
    columnHelper.accessor('email', {
      header: () => (
        <Box
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'150px'}
        >
          <Text fontSize="14px" fontWeight="700">
            EMAIL
          </Text>
        </Box>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'150px'}
        >
          <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),
    visibleColumns?.id &&
    columnHelper.accessor('id', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'60px'}
        >
          ID
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'60px'}
        >
          <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),
    visibleColumns?.location &&
    columnHelper.accessor('location', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'130px'}
        >
          GPS LOCATION
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'130px'}
        >
          <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),
    visibleColumns?.ipLocation &&
    columnHelper.accessor('ipLocation', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'130px'}
        >
          IP LOCATION
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'130px'}
        >
          <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),

    visibleColumns?.lastSessions &&
    columnHelper.accessor('lastSession', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'120px'}
        >
          LAST SESSION
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'120px'}
        >
          <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),
    visibleColumns?.totalSessions &&
    columnHelper.accessor('totalSessions', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'120px'}
        >
          TOTAL SESSIONS #
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'22px'}
          minWidth={'120px'}
        >
          <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),
    visibleColumns?.status &&
    columnHelper.accessor('status', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'90px'}
        >
          STATUS
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'90px'}
        >
          <Flex align="center" display={'flex'} gap={'4px'}>
            {info.getValue() === 'Verified' ? (
              <>{info.getValue() && <VerifiedIcon />}</>
            ) : (
              <>{info.getValue() && <CancelIcon />}</>
            )}
            <Text
              fontSize="14px"
              fontWeight={'400'}
              color={info.getValue() === 'Verified' ? '#222222' : '#E02B00'}
            >
              {info.getValue()}
            </Text>
          </Flex>
        </Box>
      ),
    }),
    visibleColumns?.subscriber &&
    columnHelper.accessor('subscriber', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'100px'}
        >
          SUBSCRIBER
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'100px'}
        >
          {info.getValue() && (
            <Text
              fontSize="14px"
              fontWeight="500"
              color={
                info.getValue() === 'Active'
                  ? '#25A259'
                  : info.getValue() === 'Trial'
                    ? '#FA8638'
                    : '#676666'
              }
              bg={
                info.getValue() === 'Active'
                  ? '#25A25914'
                  : info.getValue() === 'Trial'
                    ? '#FFCC951F'
                    : '#67666614'
              }
              padding={'1px 12px 1px 12px'}
              borderRadius={'12px'}
              border={
                info.getValue() === 'Active'
                  ? '1px solid #25A259'
                  : info.getValue() === 'Trial'
                    ? '1px solid #FA8638'
                    : '1px solid #676666'
              }
            >
              {info.getValue()}
            </Text>
          )}
        </Box>
      ),
    }),
    visibleColumns?.age &&
    columnHelper.accessor('age', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'30px'}
        >
          AGE
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
        >
          <Text
            fontSize="14px"
            fontWeight={'400'}
            color={'#222222'}
            minWidth={'30px'}
          >
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),
    visibleColumns?.gender &&
    columnHelper.accessor('gender', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'80px'}
        >
          GENDER
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'80px'}
        >
          <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),
    visibleColumns?.race &&
    columnHelper.accessor('race', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'96px'}
          maxWidth={'250px'}
        >
          RACE
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'96px'}
          maxWidth={'250px'}
          flexDirection="row" // Ensure items are aligned in a row
        >
          <Text
            fontSize="14px"
            fontWeight={'400'}
            color={'#222222'}
            style={{ flexShrink: 1 }}
          // numberOfLines={0}
          >
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),
    visibleColumns?.politicalPreferences &&
    columnHelper.accessor('politicalPreferences', {
      header: () => (
        <Text
          fontSize="14px"
          fontWeight="700"
          paddingLeft={'16px'}
          style={{
            borderLeft: '.5px solid #E7E7E7',
          }}
          minWidth={'220px'}
        >
          POLITICAL PREFERENCES
        </Text>
      ),
      cell: (info) => (
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          paddingLeft={'16px'}
          minWidth={'220px'}
        >
          <Text fontSize="14px" fontWeight={'400'} color={'#222222'}>
            {info.getValue()}
          </Text>
        </Box>
      ),
    }),
  ].filter(Boolean);

  useEffect(() => {
    if (tableData && tableData.length < 0) {
      console.log('empty');
    } else {
      console.log(
        'table data else 0 No data available----->',
        tableData[0].message,
      );
    }
  }, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // if (!data || data.length === 0) {
  //   return <Box>No data available</Box>;
  // }

  const getCurrentPageData = () => {
    return table.getRowModel().rows;
  };

  const totalPages = Math.ceil(totalUsersLength / itemsPerPage);

  const getCurrentPageSet = () => {
    return Math.floor((currentPage - 1) / 5) * 5 + 1;
  };

  const handleUserTypeChange = (value) => {
    setUserType(value);
    setCurrentPage(1);
    setItemsPerPage(value);
    setSelectedRows([]);
  };

  return (
    <>
      <Card overflowX="auto" backgroundColor={'#FFFDFB'} margin={0} padding={0}>
        <Table variant="simple" color="gray.500">
          <Thead>
            <Tr>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  borderTop="1px solid"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  py="3"
                  px="4"
                  whiteSpace="nowrap"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </Th>
              ))}
            </Tr>
          </Thead>
          {data.length > 0 ? (
            <Tbody>
              {getCurrentPageData().map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      borderBottom="1px solid"
                      borderColor={borderColor}
                      py="1"
                      px="4"
                      whiteSpace="nowrap"
                      style={{ cursor: 'pointer' }}
                    >
                      {cell.column.id === 'select' ? (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      ) : (
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                            setSingleUserData(row.original);
                            setShowProfile(true);
                            // Track profile view
                            analytics.track('people:profileview:clicked', {
                              email: currentUser.email,
                              userRole: currentUser?.role,
                              name: row.original.name,
                            });
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Box>
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : (
            <Box
              width={'250px'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Text margin={'16px 0px 0px 26px'}>No data available </Text>
            </Box>
          )}
        </Table>
      </Card>
      <Flex justify="space-between" align="center" px="25px" mt="4">
        <Flex align="center" position="relative">
          <Text fontSize="12px" color="#676666" fontWeight={'700'} mr={2}>
            Rows:
          </Text>
          {isLoading ? (
            <Spinner
              size="sm"
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#EF6506"
              ml={2}
            />
          ) : (
            <>
              <CustomSelect
                options={tableDataLimit}
                placeholder={itemsPerPage.toString()}
                width="60px"
                value={userType}
                onChange={(value) => {
                  handleUserTypeChange(value);
                  // Track rows change
                  analytics.track('people:rowschange:clicked', {
                    email: currentUser.email,
                    userRole: currentUser?.role,
                    rows: value,
                  });
                }}
                downIconFill={'#676666'}
              />
              {/* <Text
                fontSize="12px"
                color="#676666"
                ml={2}
                fontWeight={'700'}
                minW={20}
              >
                of {totalUsersLength}
              </Text> */}
            </>
          )}
        </Flex>
        <HStack spacing="2">
          <Button
            size="sm"
            onClick={() => {
              setCurrentPage(Math.max(1, currentPage - 1));
              // Track previous page
              analytics.track('people:previouspage:clicked', {
                email: currentUser.email,
                userRole: currentUser?.role,
                page: currentPage - 1,
              });
            }}
            isDisabled={currentPage === 1}
          >
            <LeftOutlineArrow
              fill={currentPage === 1 ? '#AAAAAA' : '#222222'}
            />
          </Button>

          {[...Array(5)].map((_, index) => {
            const pageNumber = getCurrentPageSet() + index;

            return pageNumber <= totalPages ? (
              <Button
                key={pageNumber}
                size="sm"
                variant={pageNumber === currentPage ? 'solid' : 'outline'}
                onClick={() => {
                  setCurrentPage(pageNumber)
                  // Track next page
                  analytics.track('people:pagechange:clicked', {
                    email: currentUser.email,
                    userRole: currentUser?.role,
                    page: pageNumber,
                  });
                }}
                color={pageNumber === currentPage ? '#EF6506' : '#222222'}
                borderColor={pageNumber === currentPage ? '#EF6506' : '#E2E8F0'}
                borderWidth={'1px'}
                borderRadius={'6px'}
              >
                {pageNumber}
              </Button>
            ) : null;
          })}

          <Button
            size="sm"
            onClick={() => {
              setCurrentPage(Math.min(totalPages, currentPage + 1));
              // Track next page
              analytics.track('people:nextpage:clicked', {
                email: currentUser.email,
                userRole: currentUser?.role,
                page: currentPage + 1,
              });
            }}
            isDisabled={currentPage === totalPages}
          >
            <RightOutlinedArrow
              fill={currentPage === totalPages ? '#AAAAAA' : '#222222'}
            />
          </Button>
        </HStack>
      </Flex>
    </>
  );
}
