import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';

// const API_URL = 'https://api.qa.twella.app/api/v1';
// const API_URL =
//   process.env.REACT_APP_API_URL ;

const DeleteUserModel = ({ onClose, isOpen, onDeleteUsers }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay bg="#53535399" />
      <ModalContent bg={bgColor} borderColor={borderColor} borderWidth="1px">
        <ModalHeader>Delete Users</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="sm" color="gray.500" mt={'-6'} mb={4}>
            are you sure wants to delete users
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={3}>
            <Button
              variant="outline"
              onClick={onClose}
              borderRadius={'8px'}
              borderColor={'#AAAAAA'}
              height={'36px'}
              fontSize={'14px'}
              fontWeight={'700'}
            >
              Cancel
            </Button>
            <Button
              bg={'#EF6506'}
              color={'white'}
              onClick={() => {
                onDeleteUsers();
                onClose();
              }}
              borderRadius={'8px'}
              borderColor={'#AAAAAA'}
              height={'36px'}
              fontSize={'14px'}
              fontWeight={'700'}
              _hover={'none'}
            >
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteUserModel;
