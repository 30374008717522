import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import './setting.css';
import MyAccount from './MyAccount';
import Team from './Team';
import Access from './Access';
import Data from './Data';
import RestrictedTopicsPlatforms from './Restrictions';
import analytics from 'segmentTracking';

const Setting = () => {
  const [selectedTab, setSelectedTab] = useState('myAccount');
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));

  const renderContent = () => {
    switch (selectedTab) {
      case 'myAccount': return <MyAccount />;
      case 'team': return <Team />;
      case 'access': return <Access />;
      case 'data': return <Data />;
      case 'restrictions': return <RestrictedTopicsPlatforms />;
      default: return null;
    }
  };

  useEffect(() => {
    // Track tab view
    analytics.track(`settings:${selectedTab}tab:viewed`, {
      tab: selectedTab,
      email: currentUser.email,
      userRole: currentUser?.role
    });
  }, [selectedTab, currentUser]);

  return (
    <Box>
      <header>
        <Text
          fontSize={'28px'}
          fontWeight={'bold'}
          color={'#222222'}
          lineHeight={'32px'}
          marginTop={10}
          // marginLeft={5}
        >
          Settings
        </Text>
        <Flex
          gap={10}
          marginTop={4}
          marginLeft={5}
          fontWeight={600}
          marginBottom={0.1}
        >
          <Text
            cursor="pointer"
            onClick={() => setSelectedTab('myAccount')}
            borderBottom={
              selectedTab === 'myAccount' ? '2.5px solid #EF6506' : 'none'
            }
            color={selectedTab === 'myAccount' ? '#EF6506' : 'none'}
          >
            My Account
          </Text>
          <Text
            cursor="pointer"
            onClick={() => setSelectedTab('team')}
            borderBottom={
              selectedTab === 'team' ? '2.5px solid #EF6506' : 'none'
            }
            color={selectedTab === 'team' ? '#EF6506' : 'none'}
          >
            Team
          </Text>
          {currentUser?.role === 'super_admin' ? (
            <Text
              cursor="pointer"
              onClick={() => setSelectedTab('access')}
              borderBottom={
                selectedTab === 'access' ? '2.5px solid #EF6506' : 'none'
              }
              color={selectedTab === 'access' ? '#EF6506' : 'none'}
            >
              Access
            </Text>
          ) : (
            ''
          )}
          {/* {currentUser?.role === 'super_admin' ? ( */}
          <Text
            cursor="pointer"
            onClick={() => setSelectedTab('data')}
            borderBottom={
              selectedTab === 'data' ? '2.5px solid #EF6506' : 'none'
            }
            color={selectedTab === 'data' ? '#EF6506' : 'none'}
          >
            Data
          </Text>
          {/* // ) : (
          //   ''
          // )} */}

          {currentUser?.role === 'super_admin' ? (
            <Text
              cursor="pointer"
              onClick={() => setSelectedTab('restrictions')}
              borderBottom={
                selectedTab === 'restrictions' ? '2.5px solid #EF6506' : 'none'
              }
              color={selectedTab === 'restrictions' ? '#EF6506' : 'none'}
            >
              Global Data
            </Text>
          ) : (
            ''
          )}
        </Flex>
        <HSeparator />
      </header>
      <main>{renderContent()}</main>
    </Box>
  );
};

export default Setting;
