import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

const Switch = ({ options, value, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(value);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <Flex
      borderRadius="full"
      bg="whites"
      //   p={1}
      align="center"
      justify="space-between"
      w="155px"
      height={'28px'}
      border={'1px solid #E7E7E7'}
    >
      {options.map((option) => (
        <Flex
          key={option}
          bg={selectedOption === option ? '#FFCC9529' : 'white'}
          height={'27px'}
          justify={'center'}
          alignItems={'center'}
          cursor="pointer"
          onClick={() => handleOptionClick(option)}
          width={'100%'}
          borderRadius={'30px'}
          border={selectedOption === option ? '1px solid  #F0A413' : 'none'}
        >
          <Text
            fontWeight={selectedOption === option ? 'bold' : 'normal'}
            fontSize={'12px'}
            color={selectedOption === option ? '#F0A413' : '#676666'}
          >
            {option}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default Switch;
