import {
  Box,
  Flex,
  Select,
  Text,
  useToast,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  Switch,
} from '@chakra-ui/react';
import Twitter from 'assets/icons/Twitter';
import Instagram from 'assets/icons/Instagram';
import TikTok from 'assets/icons/TikTok';
import YouTube from 'assets/icons/YouTube';
import Facebook from 'assets/icons/Facebook';
import Linkedin from 'assets/icons/LinkedIn';
import Reddit from 'assets/icons/Reddit';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

let socialMediaList = [
  {
    name: 'X (formerly Twitter)',
    type: 1,
    icon: Twitter,
    nameCompare: 'Twitter',
  },
  {
    name: 'Instagram',
    type: 1,
    icon: Instagram,
    nameCompare: 'Instagram',
  },
  {
    name: 'Tik Tok',
    type: 1,
    icon: TikTok,
    nameCompare: 'TikTok',
  },
  {
    name: 'YouTube shorts',
    type: 1,
    icon: YouTube,
    nameCompare: 'YouTube',
  },
  {
    name: 'Facebook',
    type: 0,
    icon: Facebook,
    nameCompare: 'Facebook',
  },
  {
    name: 'LinkedIn',
    type: 0,
    icon: Linkedin,
    nameCompare: 'Linkedin',
  },
  {
    name: 'Reddit',
    type: 0,
    icon: Reddit,
    nameCompare: 'Reddit',
  },
];

const RestrictedTopicsPlatforms = () => {
  const toast = useToast();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [switchStates, setSwitchStates] = useState({});
  const [loadingStates, setLoadingStates] = useState(false);
  const [restrictedTopics, setRestrictedTopics] = useState([]);
  const [restrictedPlatforms, setRestrictedPlatforms] = useState([]);
  const token = localStorage.getItem('token');

  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/user/profile/getAllCountries`,
        headers,
      );
      setCountries(response?.data?.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(
        `${API_URL}/user/profile/getStatesByCountryCode?countryCode=${countryId}`,
        headers,
      );
      setStates(response.data?.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  const fetchRestrictedItems = async () => {
    if (!selectedState) {
      setRestrictedTopics([]);
      setRestrictedPlatforms([]);
      return;
    }
    try {
      const response = await axios.get(
        `${API_URL}/dashboard/restrictions/getRestrictedTopicAndPlatforms?state=${selectedState}`,
        headers,
      );
      const data = response.data.data;
      setRestrictedTopics(data?.topics || []);
      setRestrictedPlatforms(data?.platforms || []);
    } catch (error) {
      console.error('Error fetching restricted items:', error);
      toast({
        title: 'Error',
        description: error?.response?.data?.message || 'State not found.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) fetchStates(selectedCountry);
  }, [selectedCountry]);

  useEffect(() => {
    fetchRestrictedItems();
  }, [selectedState, switchStates, selectedCountry]);

  const handleSwitchChange = async (id, disabledState, tab) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));
    if (!token) {
      console.error('Token not found');
      return;
    }

    // Determine the current switch state
    const isSwitchOn = switchStates[id];

    let url;
    let body;

    try {
      if (tab === 'platform') {
        url = `${API_URL}/dashboard/restrictions/${
          disabledState ? 'removeRestrictedPlatform' : 'restrictPlatform'
        }`;
        body = { state: selectedState, platform: id };
      } else {
        url = `${API_URL}/dashboard/restrictions/${
          disabledState ? 'removeRestrictedTopic' : 'restrictTopic'
        }`;
        body = { state: selectedState, topic: id };
      }

      let response;

      if (disabledState) {
        response = await axios.patch(url, body, headers);
      } else {
        response = await axios.post(url, body, headers);
      }

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      // Update the switch state
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [id]: !isSwitchOn,
      }));

      const word = tab.charAt(0).toUpperCase() + tab.slice(1);

      toast({
        title: `Successful`,
        description: !isSwitchOn ? `${word} Added` : `${word} Removed`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
    } catch (error) {
      console.error(
        'Error:',
        error.response ? error.response.data : error.message,
      );
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [id]: !prevStates[id],
      }));
    } finally {
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
    }
  };

  return (
    <Box width={'65%'} margin={'24px auto 48px'}>
      <Text fontSize={'20px'} fontWeight={'700'}>
        Restricted Topics and Platforms
      </Text>

      <Flex flexDir={'column'} margin={'20px 0'}>
        <Select
          placeholder="Select Country"
          onChange={(e) => {
            setSelectedState('');
            setSelectedCountry(e.target.value);
          }}
          value={selectedCountry}
          mb={4}
        >
          {countries.map((country) => (
            <option key={country.name} value={country?.isoCode}>
              {country.name}
            </option>
          ))}
        </Select>

        <Select
          placeholder="Select State"
          onChange={(e) => setSelectedState(e.target.value)}
          value={selectedState}
          mb={4}
          disabled={!selectedCountry}
        >
          {states.map((state) => (
            <option key={state.name} value={state.name}>
              {state.name}
            </option>
          ))}
        </Select>

        <Box mt={6}>
          <Tabs variant="enclosed">
            <TabList>
              <Tab>Topics</Tab>
              <Tab>Platforms</Tab>
            </TabList>

            <TabPanels>
              {/* Topics Tab */}
              <TabPanel>
                {restrictedTopics.length > 0 ? (
                  <Flex flexDir={'column'} margin={'10px 0'}>
                    {restrictedTopics.map((topic) => {
                      // Check if foundMedia exists and get its disabled state
                      const disabledState = topic ? topic.disabled : false;
                      const _id = topic ? topic._id : null;
                      const isLoading = loadingStates[_id] || false;

                      return (
                        <Flex
                          key={topic?._id}
                          flexDir={'column'}
                          paddingBottom={'12px'}
                          paddingTop={'12px'}
                        >
                          <Flex
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                          >
                            <Flex
                              justifyContent={'space-between'}
                              alignItems={'flex-start'}
                              opacity={!disabledState ? '1' : '0.3'}
                            >
                              {/* Render the icon */}
                              <Text
                                fontSize={'14px'}
                                lineHeight={'20px'}
                                fontWeight={'700'}
                                color={'#222222'}
                                pt={'4px'}
                                marginLeft="10px"
                              >
                                {topic?.title}
                              </Text>
                            </Flex>
                            <Box
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {isLoading ? (
                                <Flex
                                  height={'22px'}
                                  width={'44px'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                >
                                  <Spinner size={'sm'} color="#EF6506" />
                                </Flex>
                              ) : (
                                <Switch
                                  id={_id}
                                  isChecked={!disabledState || false}
                                  onChange={() =>
                                    handleSwitchChange(
                                      _id,
                                      disabledState,
                                      'topic',
                                    )
                                  }
                                  sx={{
                                    '& .chakra-switch__track': {
                                      backgroundColor: '#C2C2C2',
                                    },
                                    '& .chakra-switch__track[data-checked]': {
                                      backgroundColor: '#EF6506',
                                    },
                                  }}
                                />
                              )}
                            </Box>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                ) : (
                  <Text>No restricted topics for the selected state.</Text>
                )}
              </TabPanel>

              {/* Platforms Tab */}
              <TabPanel>
                {restrictedPlatforms.length > 0 ? (
                  <Flex flexDir={'column'} margin={'10px 0'}>
                    {socialMediaList.map((media) => {
                      const foundMedia = restrictedPlatforms.find(
                        (item) => item.name === media.nameCompare,
                      );

                      // Check if foundMedia exists and get its disabled state
                      const disabledState = foundMedia
                        ? foundMedia.disabled
                        : false;
                      const _id = foundMedia ? foundMedia._id : null;
                      const isLoading = loadingStates[_id] || false;

                      return (
                        <Flex
                          key={media?.id}
                          flexDir={'column'}
                          paddingBottom={'12px'}
                          paddingTop={'12px'}
                          borderBottom={
                            media.name === 'Reddit'
                              ? '0px'
                              : '1px solid #E7E7E7'
                          }
                          opacity={media.type === 1 ? '1' : '0.3'}
                        >
                          <Flex
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                          >
                            <Flex
                              justifyContent={'space-between'}
                              alignItems={'flex-start'}
                              opacity={!disabledState ? '1' : '0.3'}
                            >
                              {media.icon && (
                                <Box
                                  as={media.icon}
                                  fill={'#FFFDFB'}
                                  mr="8px"
                                />
                              )}{' '}
                              {/* Render the icon */}
                              <Text
                                fontSize={'14px'}
                                lineHeight={'20px'}
                                fontWeight={'700'}
                                color={'#222222'}
                                pt={'4px'}
                                marginLeft="10px"
                              >
                                {media.name}
                              </Text>
                            </Flex>
                            <Box
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {isLoading ? (
                                <Flex
                                  height={'22px'}
                                  width={'44px'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                >
                                  <Spinner size={'sm'} color="#EF6506" />
                                </Flex>
                              ) : (
                                <Switch
                                  id={_id}
                                  isChecked={
                                    (!disabledState && media.type === 1) ||
                                    false
                                  }
                                  onChange={() =>
                                    handleSwitchChange(
                                      _id,
                                      disabledState,
                                      'platform',
                                    )
                                  }
                                  disabled={media.type === 1 ? false : true}
                                  sx={{
                                    '& .chakra-switch__track': {
                                      backgroundColor: '#C2C2C2',
                                    },
                                    '& .chakra-switch__track[data-checked]': {
                                      backgroundColor: '#EF6506',
                                    },
                                  }}
                                />
                              )}
                            </Box>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                ) : (
                  <Text>No restricted platforms for the selected state.</Text>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Box>
  );
};

export default RestrictedTopicsPlatforms;
