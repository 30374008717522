import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import CustomSelect from 'components/peoplePage/CustomSelect';
import ChevronRight from 'assets/icons/chevronRight';
import EngagementGraphModal from './EngagementGraphModal';

const EngagementAreaGraph = ({
  isShowCustomSelect = true,
  categorieData = [],
  topicsBubbleData = [],
  topicsData = [],
  isModalOpen,
  modalGraph,
  periodOptions,
  period,
  onPeriodChange,
}) => {
  const [activeTab, setActiveTab] = useState('categories');
  const chartRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasData = categorieData.some((item) => item.value > 0);

  useEffect(() => {
    if (activeTab === 'categories' && chartRef.current && hasData) {
      d3.select(chartRef.current).selectAll('*').remove();

      const width = 800;
      const height = 550;

      const bubble = (data) =>
        d3.pack().size([width, height]).padding(10)(
          d3.hierarchy({ children: data }).sum((d) => d.value),
        );

      const root = bubble(categorieData);

      const svg = d3
        .select(chartRef.current)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('class', 'bubble');

      const node = svg
        .selectAll()
        .data(root.children)
        .join('g')
        .attr('class', 'node')
        .attr('transform', (d) => `translate(${d.x},${d.y})`);

      node
        .append('circle')
        .attr('r', (d) => d.r)
        .style('fill', '#FA8638')
        .style('opacity', 1);

      node
        .append('text')
        .attr('dy', '.3em')
        .style('text-anchor', 'middle')
        .style('font-size', (d) => `${d.r / 4}px`)
        .style('font-weight', '500')
        .text((d) => d.data.name);
    } else if (
      activeTab === 'topics' &&
      chartRef.current &&
      topicsBubbleData.length > 0
    ) {
      const width = 800;
      const height = 550;

      d3.select(chartRef.current).selectAll('*').remove();

      const svg = d3
        .select(chartRef?.current)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 50 ${width} ${height}`)
        .style('font-weight', '700')
        .attr('class', 'bubble');

      const pack = d3.pack().size([width, height]).padding(20);
      const root = d3
        .hierarchy({ children: topicsBubbleData })
        .sum((d) => d.value);

      const nodes = pack(root).descendants().slice(1);
      const spacing = 10;

      const node = svg
        .selectAll('.node')
        .data(nodes)
        .enter()
        .append('g')
        .attr('class', 'node')
        .attr('transform', (d) => {
          const xOffset = d.children ? spacing : 0;
          const yOffset = d.children ? spacing : 0;
          return `translate(${d.x + xOffset},${d.y + yOffset})`;
        });

      node
        .append('circle')
        .attr('r', (d) => d.r)
        .style('fill', (d) => (d.children ? '#FFEEE3' : '#FFDAB9'))
        .attr('stroke', '#FFA500')
        .attr('stroke-width', 0);

      function wrap(text, width) {
        text.each(function () {
          var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.5, // ems
            y = text.attr('y'),
            dy = parseFloat(text.attr('dy')),
            tspan = text
              .text(null)
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', dy + 'em');
          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(' '));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(' '));
              line = [word];
              tspan = text
                .append('tspan')
                .attr('x', 0)
                .attr('y', y)
                .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                .text(word);
            }
          }
        });
      }

      node
        .append('text')
        .attr('dy', (d) => (d.children ? d.r - 10 : '0'))
        .style('text-anchor', 'middle')
        .style('font-weight', '500')
        .style('font-size', (d) => {
          if (d.children) {
            return `14px`;
          } else {
            return '11px';
          }
        })
        .style('font-weight', '700')
        .style('fill', '#222222')
        .text((d) => d.data.name)
        .each(function (d) {
          if (!d.children) {
            d3.select(this).call(wrap, d.r * 1.2);
          }
        });
    }
  }, [activeTab, categorieData, topicsBubbleData, hasData]);

  useEffect(() => {
    if (modalGraph) {
      setActiveTab(modalGraph);
    }
  }, [activeTab]); // eslint-disable-line

  return (
    <>
      <Box border={'1px solid #E7E7E7'} borderRadius={'12px'}>
        {/* Header */}
        {!isModalOpen && (
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Text
                padding={'20px'}
                color={activeTab === 'categories' ? '#EF6506' : '#222222'}
                textTransform={'capitalize'}
                fontWeight={'700'}
                borderBottom={
                  activeTab === 'categories'
                    ? '2px solid #EF6506'
                    : '2px solid transparent'
                }
                cursor={'pointer'}
                onClick={() => setActiveTab('categories')}
              >
                categories
              </Text>
            </Box>
            {isShowCustomSelect && (
              <Box paddingRight={'20px'}>
                <CustomSelect
                  options={periodOptions}
                  placeholder="6 months"
                  width="150px"
                  value={period}
                  onChange={onPeriodChange}
                />
              </Box>
            )}
          </Box>
        )}

        {/* Body */}
        {activeTab === 'categories' ? (
          <Box
            display={'flex'}
            borderTop={'1px solid #E7E7E7'}
            borderBottom={'1px solid #E7E7E7'}
          >
            {/* Left */}
            <Box
              flexBasis="50%"
              display="flex"
              flexDirection="column"
              borderRight="1px solid #E7E7E7"
              padding="32px 16px"
              className="EngagementCategories"
              height={'300px'}
            >
              {categorieData?.map((elem, index) => (
                <Box key={index}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    pb={'4px'}
                  >
                    <Text
                      textTransform={'capitalize'}
                      fontWeight={'500'}
                      fontSize={'12px'}
                      lineHeight={'16px'}
                      color={'#222222'}
                    >
                      {elem?.name}
                    </Text>
                    <Text
                      fontWeight={'700'}
                      fontSize={'12px'}
                      lineHeight={'14px'}
                      color={'#222222'}
                    >{`${elem?.value?.toFixed(1)}%`}</Text>
                  </Box>
                  <Box bg={'#E7E7E7'} borderRadius={'2px'}>
                    <Box
                      width={`${elem?.value}%`}
                      bg={'#FA8638'}
                      height={'6px'}
                      borderRadius={'2px'}
                    ></Box>
                  </Box>
                </Box>
              ))}
            </Box>

            {/* Right */}
            <Box flexBasis={'50%'}>
              {hasData ? (
                <Box ref={chartRef} className="chart-container"></Box>
              ) : (
                <Text
                  textAlign="center"
                  fontWeight="500"
                  fontSize="14px"
                  color="#666666"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  No data available
                </Text>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            display={'flex'}
            borderTop={'1px solid #E7E7E7'}
            borderBottom={'1px solid #E7E7E7'}
          >
            {/* Topics view (left empty as per the original code) */}
          </Box>
        )}

        {/* Footer */}
        {!isModalOpen && (
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            padding={'16px'}
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              cursor={'pointer'}
              onClick={onOpen}
            >
              <Text
                textTransform={'capitalize'}
                color={'#EF6506'}
                lineHeight={'16px'}
                fontWeight={'500'}
                fontSize={'12px'}
              >
                view more
              </Text>
              <ChevronRight />
            </Box>
          </Box>
        )}
      </Box>
      <EngagementGraphModal
        isOpen={isOpen}
        onClose={onClose}
        activeTab={activeTab}
        categorieData={categorieData}
      />
    </>
  );
};

export default EngagementAreaGraph;