import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Switch,
  Text,
  Input,
  // Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import CopyPasteIcon from 'assets/icons/CopyPasteIcon';
import CustomSelect from 'components/peoplePage/CustomSelect';
import ShareAndroidIcon from 'assets/icons/shareAndroidIcon';
import axios from 'axios';
import { useGate } from 'statsig-react';

const API_URL =
  process.env.REACT_APP_API_URL ;

const Team = () => {
  const { value: toggleShareLink } = useGate('settings_display_share_link'); // useGate inside a custom hook
  const [copyLink, setCopyLink] = useState('');
  const [sendInviteEmail, setSendInvite] = useState('');
  const [shareLinkSwitch, setShareLinkSwitch] = useState(true);
  const inputRef = useRef(null);
  const [checkValidEmail, setCheckValidEmail] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  // const [selectedPeriod, setSelectedPeriod] = useState('All Period');
  // const [inviteSendViaEmail, setInviteSendViaEmail] = useState(false);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false); // eslint-disable-line
  const [teamMember, setTeamMember] = useState([]);
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));
  const usersOptions = [
    { value: 'all', display: 'All members' },
    { value: 'admin', display: 'Admin' },
    { value: 'member', display: 'Member' },
  ];

  const handleCopy = () => {
    inputRef.current.select();
    navigator.clipboard.writeText(copyLink);
  };

  const handleSelectedStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleInviteSendViaEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const token = localStorage.getItem('token'); // Ensure you are getting the correct token
      const currentInstitution = JSON.parse(
        localStorage.getItem('institution'),
      );
      const headers = {
        Authorization: `Bearer ${token}`, // Pass the correct token here
      };

      let body = {};
      if (sendInviteEmail) {
        body.email = sendInviteEmail;
      }
      body.institution = currentInstitution?._id;
      // console.log('body ----->', body);

      const response = await axios.post(
        `${API_URL}/dashboard/users/inviteUser`,
        body,
        { headers: headers },
      );

      // console.log('RESPONSE DATA  =>', response.data);

      const { message } = response.data;

      toast({
        title: 'Invite Sent',
        description: message || 'Invitation has been sent successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // onSendInvite({ sendInviteEmail });
      setSendInvite('');
    } catch (error) {
      console.log('invite error', error);

      toast({
        title: 'Error',
        description:
          error.response?.data?.message ||
          'An error occurred while sending the invite.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
    // setInviteSendViaEmail(true);
  };

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const token = localStorage.getItem('token');
        const { _id } = JSON.parse(localStorage.getItem('institution'));

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${API_URL}/dashboard/teams?role=&skip=0&limit=10&institution=${_id}`,
          { headers },
        );

        if (response?.data?.success) {
          setTeamMember(response?.data?.data);
        } else {
          console.log('Network error');
        }
      } catch (error) {
        console.log(`Error -> ${error.message}`);
      }
    };
    fetchTeamMembers();
  }, []);

  useEffect(() => {
    setCheckValidEmail(validateEmail(sendInviteEmail));
  }, [sendInviteEmail]);

  return (
    <Box width={'65%'} margin={'24px auto 48px'}>
      {/* Invite Members */}
      <Box paddingBottom={'48px'} borderBottom={'1px solid #E7E7E7'}>
        <Box>
          <Text fontSize={'20px'} fontWeight={'700'} lineHeight={'24px'}>
            Invite members
          </Text>
        </Box>
        {toggleShareLink && (
          <>
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              margin={'12px 0'}
            >
              <Box>
                <Text>Share this link to invite others to Twella</Text>
              </Box>

              <Box>
                <Switch
                  isChecked={shareLinkSwitch}
                  onChange={() => setShareLinkSwitch(!shareLinkSwitch)}
                  mr="2"
                  sx={{
                    '& .chakra-switch__track': {
                      backgroundColor: '#C2C2C2',
                    },
                    '& .chakra-switch__track[data-checked]': {
                      backgroundColor: '#EF6506',
                    },
                  }}
                />
              </Box>
            </Flex>
            <Flex>
              <Box flexBasis={'90%'}>
                <input
                  ref={inputRef}
                  name="copyLink"
                  id="copyLink"
                  value={copyLink}
                  onChange={(e) => setCopyLink(e.target.value)}
                  placeholder="https://twella.app/"
                  style={{
                    outline: 'none',
                    // border: 'none',
                    border: '1.1px solid #E7E7E7',
                    background: 'transparent',
                    padding: '5px 12px',
                    width: '100%',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                />
              </Box>
              <Flex
                bg={'#EF6506'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={'0 8px'}
                flexBasis={'10%'}
                borderRightRadius={'8px'}
                onClick={handleCopy}
                cursor={'pointer'}
              >
                <Box>
                  <CopyPasteIcon />
                </Box>
                <Box>
                  <Text
                    color={'#FFFDFB'}
                    fontWeight={'700'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    marginLeft={'2px'}
                  >
                    Copy
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </>
        )}

        {currentUser?.role !== 'member' ? (
          <>
            <Box pt={'16px'}>
              <Text>Send Twella email invites</Text>
            </Box>
            {/* Send invite input  */}
            <Flex pt={'8px'}>
              <Box flexBasis={'85%'}>
                <input
                  // ref={inputRef}
                  name="sendInviteEmail"
                  id="sendInviteEmail"
                  type="email"
                  value={sendInviteEmail}
                  onChange={(e) => setSendInvite(e.target.value)}
                  placeholder="mymail@gmail.com"
                  style={{
                    outline: 'none',
                    // border: 'none',
                    border: '1.1px solid #E7E7E7',
                    background: 'transparent',
                    padding: '5px 12px',
                    width: '100%',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                />
              </Box>
              <Flex
                bg={checkValidEmail ? '#EF6506' : '#EF650680'}
                justifyContent="center"
                alignItems="center"
                padding="0 8px"
                flexBasis="15%"
                borderRightRadius="8px"
                onClick={checkValidEmail ? handleInviteSendViaEmail : undefined}
                cursor={checkValidEmail ? 'pointer' : 'not-allowed'}
                opacity={checkValidEmail ? 1 : 0.5}
                pointerEvents={checkValidEmail ? 'auto' : 'none'}
              >
                {/* <Box>
                  <ShareAndroidIcon />
                </Box> */}
                <Box>
                  <Text
                    color="#FFFDFB"
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="20px"
                    ml="4px"
                  >
                    Send invite
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </>
        ) : (
          ''
        )}
      </Box>

      {/* Manage Members */}
      <Box marginTop={'32px'}>
        <Box>
          <Text fontSize={'20px'} fontWeight={'700'} lineHeight={'24px'}>
            Manage members
          </Text>
        </Box>

        <Box mt={'24px'}>
          <Flex mb={4}>
            <Box mr={'8px'}>
              <Input
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>

            <Box mr={'8px'}>
              <CustomSelect
                options={usersOptions}
                width="150px"
                placeholder={'All members'}
                value={selectedStatus}
                onChange={(newStatus) => handleSelectedStatusChange(newStatus)}
              />
            </Box>
          </Flex>
          <Box border={'1px solid #E7E7E7'} borderRadius={'12px'}>
            <Table>
              <Thead borderBottomWidth={2} borderBottomColor="#E7E7E7">
                <Tr>
                  <Th>Name</Th>
                  <Th borderLeft={'1px solid #E7E7E7'}>
                    <Text>Email</Text>
                  </Th>
                  <Th borderLeft={'1px solid #E7E7E7'}>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {teamMember
                  ?.filter((member) => {
                    // Check if the search term is matched
                    let matchesSearchTerm = true;
                    if (searchTerm) {
                      matchesSearchTerm = member?.fullName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase());
                    }
                    // Check if the selectedStatus matches the member's role
                    const matchesStatus =
                      selectedStatus === 'all' ||
                      member?.role === selectedStatus;

                    // Return members that match both the search term and status
                    return matchesSearchTerm && matchesStatus;
                  })
                  .map((member, index) => {
                    const capSuperAdmin = member?.role
                      .split('_')
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase(),
                      )
                      .join(' ');

                    return (
                      <Tr key={index}>
                        <Td borderColor="#E7E7E7">{member?.fullName}</Td>
                        <Td borderColor="#E7E7E7">{member?.email}</Td>
                        <Td borderColor="#E7E7E7">{capSuperAdmin}</Td>
                      </Tr>
                    );
                  })}

                {teamMember?.filter((member) => {
                  const matchesSearchTerm = member?.name
                    ?.toLowerCase()
                    .includes(searchTerm?.toLowerCase());

                  const matchesStatus =
                    selectedStatus === 'all' || member?.role === selectedStatus;

                  return matchesSearchTerm && matchesStatus;
                }).length === 0 && (
                  <Tr>
                    <Td colSpan="3" textAlign="center" borderColor="#E7E7E7">
                      No records found
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Team;
