import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from '../../utils/helper'

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-3 py-1 text-xs font-bold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        primary: "bg-[#EF65063D] border-[#EF6506] text-[#EF6506]",
        secondary: "bg-[#F0A41329] border-[#F0A413] text-[#F0A413]",
        tertiary: "bg-[#AAAAAA3D] border-[#AAAAAA] text-[#676666]",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
}

function Badge({ 
  className, 
  variant, 
  bgColor, 
  borderColor, 
  textColor, 
  ...props 
}: BadgeProps) {
  // Generate dynamic inline styles
  const dynamicStyles: React.CSSProperties = {};
  
  if (bgColor) dynamicStyles.backgroundColor = bgColor;
  if (borderColor) dynamicStyles.borderColor = borderColor;
  if (textColor) dynamicStyles.color = textColor;

  // Combine base variant classes with dynamic styling
  const combinedClassName = cn(
    badgeVariants({ variant }),
    className
  );

  return (
    <div 
      className={combinedClassName} 
      style={Object.keys(dynamicStyles).length ? dynamicStyles : undefined}
      {...props} 
    />
  )
}

export { Badge, badgeVariants }
