import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fullName: '',
  profileImage: '',
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setUserProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
  },
});

export const { setUserFullName, setUserProfileImage } = userDataSlice.actions;

export default userDataSlice.reducer;
