import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';

const CustomSelect = ({ options, placeholder, width, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (option) => {
    if (typeof onChange === 'function') {
      onChange(option);
    }
    setIsOpen(false);
  };

  const getDisplayValue = () => {
    const selectedOption = options.find((option) => option._id === value);
    return selectedOption ? selectedOption.name : placeholder;
  };

  return (
    <Popover
      placement="bottom-start"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <PopoverTrigger>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width={width}
          border="1px solid #E7E7E7"
          borderRadius="8px"
          px={3}
          py={2}
          cursor="pointer"
          _hover={{ borderColor: '#EF6506' }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Text pr={1} fontSize="sm">{getDisplayValue()}</Text>
          <ChevronDownIcon />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        width={width}
        boxShadow="md"
        border="1px solid #EF6506"
        borderRadius="8px"
        _focus={{ outline: 'none', boxShadow: 'none' }}
      >
        <PopoverBody p={0}>
          <VStack align="stretch" spacing={0}>
            {options.map((option) => (
              <Box
                key={option._id}
                px={3}
                py={2}
                _hover={{ bg: '#F7F7F7' }}
                cursor="pointer"
                onClick={(event) => handleChange(option?._id)}
              >
                <Text fontSize="sm">{option.name}</Text>
              </Box>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CustomSelect;
