import React from 'react';
import {
  Flex,
  Stat,
  StatNumber,
  useColorModeValue,
  Text,
  Box,
} from '@chakra-ui/react';
import ArrowDownIcon from '../../assets/icons/arrowDownIcon';
import ArrowUpIcon from '../../assets/icons/ArrowUpIcon';

export default function MiniStatistics(props) {
  const { name, value, syntax, text, syntaxFill } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  // const bgIcon = useColorModeValue('secondaryGray.300', 'navy.700');

  return (
    <Box
      bg={'#FFFDFB'}
      borderRadius={'12px'}
      border={'1px'}
      borderColor={'#E7E7E7'}
      padding={'10px'}
      alignItems={'center'}
      display={'flex'}
    >
      <Flex flexDirection="row" align="center" justify="space-between" w="100%">
        <Stat me="auto">
          {/* <StatLabel
            fontSize="14px"
            color="#676666"
            fontWeight="bold"
            pb=".1rem"
          >
            {name}
          </StatLabel> */}
          <Flex align="center">
            <Box
              fontSize="14px"
              color="#676666"
              fontWeight="700"
              pb=".1rem"
              width={name.toLowerCase() === 'total sessions' ? '105px' : 'auto'}
              mr={name.toLowerCase() === 'total sessions' ? '0px' : '8px'}
              // bg={'red'}
            >
              {name}
            </Box>

            {name !== 'Total users' && (
              <Box
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color={iconColor}
                fontSize="24px"
                flexBasis={name.toLowerCase() === 'total users' ? 0 : '8%'}
                // pr={'8px'}
              >
                {syntax === 'less' ? (
                  <ArrowDownIcon fill={syntaxFill} />
                ) : (
                  <ArrowUpIcon fill={syntaxFill} />
                )}
              </Box>
            )}
            <StatNumber
              fontSize="24"
              color={textColor}
              marginRight={'2'}
              marginLeft={'2'}
            >
              {value}
            </StatNumber>
            <Text fontSize={'14px'}>{text}</Text>
          </Flex>
        </Stat>
      </Flex>
    </Box>
  );
}
