import React from 'react';

function InviteNewPeople({ fill = '#222222', ...prop }) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 9.83333H4.99992C4.06954 9.83333 3.60435 9.83333 3.22582 9.94816C2.37356 10.2067 1.70661 10.8736 1.44808 11.7259C1.33325 12.1044 1.33325 12.5696 1.33325 13.5M12.6666 13.5V9.5M10.6666 11.5H14.6666M9.66659 4.5C9.66659 6.15685 8.32344 7.5 6.66659 7.5C5.00973 7.5 3.66659 6.15685 3.66659 4.5C3.66659 2.84315 5.00973 1.5 6.66659 1.5C8.32344 1.5 9.66659 2.84315 9.66659 4.5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InviteNewPeople;
