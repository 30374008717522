import React from 'react';
import { Box, Text, Flex, ChakraProvider, extendTheme } from '@chakra-ui/react';
import LineBarChart from './LineBarChart';
import Switch from 'components/Switch';
import analytics from 'segmentTracking';
import { Badge } from '../Badges';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select';
import { getDisplayValue } from '../../utils/getDisplayName';

const theme = extendTheme({
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
});

// const data = [
//   { xAxis: 'Feb', value1: 60, value2: 30, value3: 20 },
//   { xAxis: 'Mar', value1: 80, value2: 90, value3: 60 },
//   { xAxis: 'Apr', value1: 40, value2: 60, value3: 120 },
//   { xAxis: 'May', value1: 90, value2: 50, value3: 70 },
//   { xAxis: 'Jun', value1: 70, value2: 90, value3: 30 },
//   { xAxis: 'Jul', value1: 60, value2: 70, value3: 90 },
//   { xAxis: 'Aug', value1: 50, value2: 40, value3: 60 },
// ];

const chipsData = [
  { label: 'Author', variant: 'primary' },
  { label: 'Evidence', variant: 'secondary' },
  { label: 'Influence', variant: 'tertiary' },
];

const EvaluationSkillsBarGraph = ({
  data,
  period,
  onPeriodChange,
  periodOptions,
  barLables,
  selectedOption,
  setSelectedOption,
  pageName,
  currentUser
}) => {
  // const [period, setPeriod] = useState('6 Months');
  // const [selectedOption, setSelectedOption] = useState('Average');

  const handleOptionChange = (option) => {

    analytics.track(`${pageName}:switch:${option}:clicked`, {
      properties: {
        email: currentUser?.email,
        userRole: currentUser?.role,
        screen : pageName,
        graphName : 'evaluationSkills',
        selectedOption: option,
      },
    });

    setSelectedOption((prevState) => ({
      ...prevState,
      evaluationSkills: {
        value: option === 'Average' ? 'avg' : 'median',
        label: option,
      },
    }));
  };

  return (
    <ChakraProvider theme={theme}>
      <Box
        bg="transparent"
        borderRadius="12px"
        overflow="hidden"
        border="1px solid #E7E7E7"
        paddingBottom={'3%'}
        // textTransform={'capitalize'}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={4}
          borderBottom={'1px solid #E7E7E7'}
        >
          <Box>
            <Text fontSize="18px" fontWeight="600" color="#101828">
              Analytical skills
            </Text>
            <Text fontSize="14px" color="#475467" mt={1}>
              Avg time for each prompt during Closer look
            </Text>
          </Box>
          <Select onValueChange={onPeriodChange}>
            <SelectTrigger className="w-[150px]">
              <SelectValue placeholder={getDisplayValue(period, periodOptions)} />
            </SelectTrigger>
            <SelectContent>
              {periodOptions?.map((elem, index) => (
                <SelectItem value={elem?.value} key={index}>{elem?.display}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          mt={'8px'}
          mb={'8px'}
        >
          <Flex alignItems="baseline">
            <Switch
              options={['Average', 'Median']}
              value={selectedOption?.evaluationSkills?.label}
              onChange={handleOptionChange}
            />
          </Flex>
          <Flex justifyContent="flex-start" px={4} mt={4} gap={'8px'}>
            {chipsData.map((chip, index) => (
               <Badge variant={chip?.variant}>{chip.label}</Badge>
            ))}
          </Flex>
        </Flex>
        <LineBarChart
          data={data}
          isBarBackground={false}
          yAxisLabel={'m'}
          barLables={barLables}
        />
      </Box>
    </ChakraProvider>
  );
};

export default EvaluationSkillsBarGraph;
