// Session Management Utilities

import React from 'react';

/**
 * Checks if an error indicates a session timeout
 * @param {Object} error - The error object to check
 * @returns {boolean} Indicates if the error is a session timeout
 */
export const isSessionTimeoutError = (error) => {
  return !!(
    (error?.response &&
      error?.response.status === 401 &&
      error?.response?.data?.message
        ?.toLowerCase()
        .includes('jwt malformed' || 'session' || 'jwt')) ||
    error === 'sessionExpired'
  );
};

/**
 * Hook to handle global session timeout and error management
 * @param {Object} [options={}] - Configuration options for session timeout handling
 * @param {string} [options.redirectRoute='/signin'] - Route to navigate on session timeout
 * @param {Function} [options.clearSessionData] - Custom function to clear session data
 * @param {Function} [options.signOut] - Custom sign-out function
 * @param {Function} [options.navigate] - Navigation function (from react-router-dom)
 */
export const useSessionTimeout = (options = {}) => {
  const {
    redirectRoute = '/signin',
    clearSessionData = defaultClearSessionData,
    signOut = defaultSignOut,
    navigate,
  } = options;

  React.useEffect(() => {
    const handleSessionTimeout = (error) => {
      if (isSessionTimeoutError(error)) {
        // Clear session data
        clearSessionData();
        // Sign out the user
        signOut();
        localStorage.clear();
        sessionStorage.clear();

        // Redirect to sign-in page
        if (navigate) {
          navigate(redirectRoute);
        } else {
          window.location.href = redirectRoute;
        }
      }
    };

    // Set up a global error handler to catch all API errors
    const errorHandler = (event) => {
      handleSessionTimeout(event.error);
      // You can add additional global error handling logic here
    };

    // Attach the error handler to the global error event
    window.addEventListener('error', errorHandler);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, [redirectRoute, clearSessionData, signOut, navigate]);
};

/**
 * Default method to clear session data from localStorage
 */
const defaultClearSessionData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

/**
 * Default sign-out method (can be overridden)
 */
const defaultSignOut = () => {
  // Implement your default sign-out logic
  // This can be expanded based on your specific authentication setup
  console.log('User signed out');
};

/**
 * Utility function to handle session timeout manually
 * @param {Object} error - The error object
 * @param {Object} [options={}] - Configuration options for session timeout handling
 * @param {string} [options.redirectRoute='/signin'] - Route to redirect on timeout
 * @param {Function} [options.clearSessionData] - Custom function to clear session data
 * @param {Function} [options.signOut] - Custom sign-out function
 */
export const handleSessionTimeout = (error, options = {}) => {
  const {
    redirectRoute = '/signin',
    clearSessionData = defaultClearSessionData,
    signOut = defaultSignOut,
  } = options;

  if (isSessionTimeoutError(error)) {
    clearSessionData();
    signOut();
    window.location.href = redirectRoute;
  }
};

/**
 * Creates an axios interceptor for handling session timeouts
 * @param {Object} axiosInstance - Axios instance to add interceptor to
 * @param {Object} [options={}] - Configuration options
 * @returns {Function} Eject function to remove the interceptor
 */
export const createSessionTimeoutInterceptor = (
  axiosInstance,
  options = {},
) => {
  const {
    redirectRoute = '/signin',
    clearSessionData = defaultClearSessionData,
    signOut = defaultSignOut,
  } = options;

  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isSessionTimeoutError(error)) {
        clearSessionData();
        signOut();
        window.location.href = redirectRoute;
        localStorage.clear();
        sessionStorage.clear();
      }
      return Promise.reject(error);
    },
  );

  // Return a function to eject the interceptor if needed
  return () => {
    axiosInstance.interceptors.response.eject(interceptor);
  };
};
