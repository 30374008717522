import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  VStack,
  HStack,
  useColorModeValue,
  Input,
} from '@chakra-ui/react';

const EditModal = ({ onClose, isOpen, data, handleEditName }) => {
  const [name, setName] = useState('');
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    if (data?.fullName) {
      setName(data?.fullName);
    }
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay bg="#53535399" />
      <ModalContent
        bg={bgColor}
        borderColor={borderColor}
        borderWidth="1px"
        borderRadius={'12px'}
        alignSelf={'center'}
      >
        <ModalHeader paddingBottom={0}>Edit Name</ModalHeader>
        <ModalCloseButton style={{ marginTop: '8px' }} />
        <ModalBody>
          <form onSubmit={handleEditName}>
            <VStack>
              <FormControl>
                <Input
                  id="name"
                  type={'text'}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Name"
                  borderColor={'#AAAAAA'}
                  focusBorderColor="#EF6506"
                  _hover={{ border: ' 1px solid #EF6506' }}
                  borderRadius={'8px'}
                />
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={3}>
            <Button
              variant="outline"
              onClick={onClose}
              borderRadius={'8px'}
              padding={'6px 12px'}
            >
              Cancel
            </Button>
            <Button
              bg={'#EF6506'}
              color={'white'}
              onClick={() => {
                handleEditName(name);
                onClose();
              }}
              borderRadius={'8px'}
              borderColor={'#AAAAAA'}
              height={'36px'}
              fontSize={'14px'}
              fontWeight={'700'}
              _hover={'none'}
              isDisabled={!name}
              padding={'6px 12px'}
            >
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
