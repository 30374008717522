import React from 'react';
import { Box, Text, Flex, ChakraProvider, extendTheme } from '@chakra-ui/react';
import LineBarChart from './LineBarChart';
import ArrowDownIcon from 'assets/icons/arrowDownIcon';
import ArrowUpIcon from 'assets/icons/ArrowUpIcon';
import Switch from 'components/Switch';
import analytics from 'segmentTracking';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select';
import { getDisplayValue } from '../../utils/getDisplayName';

const theme = extendTheme({
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
});

const ActivityTimeBarGraph = ({
  data,
  period,
  onPeriodChange,
  graphData,
  periodOptions,
  selectedOption,
  setSelectedOption,
  pageName,
  currentUser,
}) => {
  // const [selectedOption, setSelectedOption] = useState('Total');

  const handleOptionChange = (option) => {
    analytics.track(`${pageName}:switch:${option}:clicked`, {
      properties: {
        email: currentUser?.email,
        userRole: currentUser?.role,
        screen: pageName,
        graphName: 'activityTime',
        selectedOption: option,
      },
    });

    setSelectedOption((prevState) => ({
      ...prevState,
      activityTime: {
        value: option === 'Total' ? 'total' : 'avg',
        label: option,
      },
    }));
  };

  // const syntax = 'isSyntaxUp';
  const syntaxFill = '#25A259';

  function convertTimeToHours(seconds) {
    if (seconds < 60) {
      return `0hrs`;
    }
    return `${(seconds / 3600).toFixed(2)}hrs`; // 3600 seconds in an hour
  }

  return (
    <ChakraProvider theme={theme}>
      <Box
        bg="transparent"
        borderRadius="12px"
        overflow="hidden"
        border="1px solid #E7E7E7"
        paddingBottom={'3%'}
        // textTransform={'capitalize'}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={4}
          pt={4}
          borderBottom={'1px solid #E7E7E7'}
        >
          <Box>
            <Text fontSize="18px" fontWeight="600" color="#101828">
              Activity Time
            </Text>
            <Text fontSize="14px" color="#475467" mt={1}>
              Total amount of time using app
            </Text>
          </Box>
          <Select onValueChange={onPeriodChange}>
            <SelectTrigger className="w-[150px]">
              <SelectValue
                placeholder={getDisplayValue(period, periodOptions)}
              />
            </SelectTrigger>
            <SelectContent>
              {periodOptions?.map((elem, index) => (
                <SelectItem value={elem?.value} key={index}>
                  {elem?.display}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={2}
          mt={'16px'}
          mb={8}
          width={'95%'}
        >
          <Switch
            options={['Total', 'Average']}
            value={selectedOption?.activityTime?.label}
            onChange={handleOptionChange}
          />
          <Flex alignItems="baseline">
            <Text fontSize="20px" fontWeight="600" color="#101828">
              {convertTimeToHours(graphData?.totalTime || 0)}
            </Text>
            <Text fontSize="14px" color="#475467" ml={2}>
              total
            </Text>
          </Flex>

          <Flex alignItems={'center'}>
            <Box mr={'2px'}>
              {graphData?.growth === 'less' ? (
                <ArrowDownIcon fill={syntaxFill} />
              ) : (
                <ArrowUpIcon fill={syntaxFill} />
              )}
            </Box>
            <Text fontSize="20px" fontWeight="600" color="#101828">
              {`${
                graphData?.percentage?.toFixed(1)
                  ? `${graphData?.percentage?.toFixed(1)}%`
                  : ''
              }`}
            </Text>
            <Text fontSize="11px" color="#475467" ml={2}>
              since last period
            </Text>
          </Flex>
        </Flex>
        <LineBarChart data={data} isBarBackground={false} yAxisLabel={'m'} />
      </Box>
    </ChakraProvider>
  );
};

export default ActivityTimeBarGraph;
