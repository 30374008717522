import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Flex,
  Text,
  useColorModeValue,
  // useMediaQuery,
} from '@chakra-ui/react';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';
import Switch from 'components/Switch';
import CustomSelect from 'components/peoplePage/CustomSelect';

const AppActivityBarGraph = ({
  data,
  period,
  onPeriodChange,
  periodOptions,
  totalSessions,
  totalPosts,
  graphData,
  selectedOption,
  setSelectedOption,
}) => {
  // const [isLargerThan850] = useMediaQuery('(min-width: 850px)');
  // const [selectedOption, setSelectedOption] = useState('Total');
  const [chartData, setChartData] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const fontColor1 = '#676666';

  useEffect(() => {
    if (Array?.isArray(data) && data?.length > 0) {
      setChartData(data);
    } else {
      // If data is not valid, set a default empty state
      setChartData([]);
    }
  }, [data]);

  const handleOptionChange = (option) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      appActivity: {
        value: option === 'Total' ? 'total' : 'avg',
        label: option,
      },
    }));
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload?.length) {
      return (
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: 8,
            padding: '20px',
            border: '2px solid #E7E7E7',
          }}
        >
          <p style={{ fontWeight: 'bold' }}>{`Day: ${label}`}</p>
          <p style={{ fontWeight: 'medium' }}>
            Posts: {payload[0]?.value.toFixed(0)}
          </p>
        </div>
      );
    }
    return null;
  };

  const maxValue =
    chartData?.length > 0
      ? Math.max(...chartData?.map((item) => item?.vetted?.toFixed(0)))
      : 0;
  const yAxisMax = maxValue % 2 === 0 ? maxValue + 2 : maxValue + 1;

  console.log('data of app activity------>', data);

  return (
    <>
      <Box className="personalDataCard">
        <Box>
          <Text fontWeight="bold">App Activity</Text>
          <Text fontSize="12px" fontWeight="400" color={fontColor1}>
            Last session: today
          </Text>
        </Box>
        <CustomSelect
          options={periodOptions || []}
          placeholder="6 months"
          width="150px"
          value={period}
          onChange={onPeriodChange}
        />
      </Box>

      <Box bg="#E7E7E7" height="1px">
        <Text display="none">line</Text>
      </Box>

      <Card
        direction="column"
        w="100%"
        className="barChat"
        padding="15px 0 10px"
      >
        <Flex
          justify="space-between"
          align="start"
          px="10px"
          width="95%"
          pb="16px"
          pr="18px"
          pl="14px"
        >
          <Switch
            options={['Total', 'Average']}
            value={selectedOption?.appActivity?.label}
            onChange={handleOptionChange}
          />
          <Flex flexDirection="column" align="start" me="20px">
            <Flex align="center">
              <Text
                color={textColor}
                fontSize="20px"
                fontWeight="700"
                lineHeight="100%"
              >
                {graphData?.totalSessions || 0}
              </Text>
              <Text ms="6px" color="#676666" fontSize="14px" fontWeight="400">
                total sessions
              </Text>
            </Flex>
          </Flex>
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="20px"
              fontWeight="700"
              lineHeight="100%"
            >
              {graphData?.totalPosts || 0}
            </Text>
            <Text ms="6px" color="#676666" fontSize="14px" fontWeight="400">
              posts
            </Text>
          </Flex>
        </Flex>

        <Text
          alignSelf="flex-start"
          pl="16px"
          fontSize="10px"
          color="#676666"
          fontWeight="400"
          pb="8px"
          lineHeight="10px"
        >
          Posts
        </Text>
        <ResponsiveContainer width="100%" height={240}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="5 5" vertical={false} />
            <XAxis
              dataKey="time"
              axisLine={true}
              tick={true}
              style={{
                fontSize: '12px',
                fontWeight: '400',
                fill: '#A3AED0',
              }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: '12px',
                fill: '#CBD5E0',
              }}
              domain={[0, yAxisMax]}
              tick={{
                fill: '#475467',
                fontSize: 10,
                textAnchor: 'start',
                dx: -40,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey={'vetted' || 'value1'}
              fill="#FA8638"
              radius={5}
              barSize={10}
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </>
  );
};

export default AppActivityBarGraph;
