import { useState } from 'react';

const {
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Flex,
  Switch,
  Box,
  Text,
} = require('@chakra-ui/react');
const CustomizeTablePopup = ({
  visibleColumns,
  setVisibleColumns,
  isOpen,
  onClose,
}) => {
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));

  // Function to toggle column visibility
  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  // List of all column names
  const columnNames = [
    'name',
    'email',
    'id',
    'location',
    'lastSessions',
    'totalSessions',
    'status',
    'subscriber',
    'subscription',
    'age',
    'gender',
    'race',
    ...(currentUser?.role === 'super_admin' ? ['politicalPreferences'] : []),
  ];

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="left-start">
      <PopoverContent
        width="217px"
        position="absolute"
        // right="calc(0% + -190px)"
        right="calc(0% + -50px)"
        top="50px"
        borderColor={'#EF6506'}
        borderWidth={'1px'}
      >
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontSize={'14px'} fontWeight={'500'} color={'#222222'}>
          Table options
        </PopoverHeader>
        <PopoverBody>
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Text fontSize={'12px'} fontWeight={'500'} color={'#676666'}>
              COLUMNS
            </Text>
            <Text fontSize={'12px'} fontWeight={'500'} color={'#676666'}>
              HIDE/SHOW
            </Text>
          </Flex>
          {columnNames.map((column) => (
            <Box
              key={column}
              display="flex"
              alignItems="center"
              mb="2"
              justifyContent={'space-between'}
            >
              <Text fontSize={'14px'} fontWeight={'400'} color={'#222222'}>
                {column.charAt(0).toUpperCase() + column.slice(1)}
              </Text>
              <Switch
                id={column}
                isChecked={visibleColumns[column]}
                onChange={() => toggleColumnVisibility(column)}
                mr="2"
                sx={{
                  '& .chakra-switch__track': {
                    backgroundColor: '#C2C2C2',
                  },
                  '& .chakra-switch__track[data-checked]': {
                    backgroundColor: '#EF6506',
                  },
                }}
              />
            </Box>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CustomizeTablePopup;
