import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import EngagementAreaGraph from './EngagementCategories';

const EngagementGraphModal = ({
  onClose,
  isOpen,
  activeTab,
  categorieData,
}) => {

  const topicsData = [
    { name: '2024 Elections', value: 40 },
    { name: 'Fitness', value: 21 },
    { name: 'Olympics 2024', value: 14 },
    { name: 'AI', value: 10 },
    { name: 'Food', value: 8 },
    { name: 'Climate', value: 7 },
  ];

  const topicsBubbleData = [
    {
      name: 'Media',
      children: [
        { name: '2024 Elections', value: 40 },
        { name: 'Trump', value: 30 },
        { name: 'Ukraine', value: 20 },
        { name: 'UK Riots', value: 25 },
        { name: 'NYT', value: 28 },
      ],
    },
    {
      name: 'Tech',
      children: [
        { name: 'AI', value: 35 },
        { name: 'Climate Tech', value: 30 },
        { name: 'Nvidia', value: 25 },
        { name: 'VC', value: 20 },
        { name: 'X', value: 15 },
      ],
    },
    {
      name: 'Health',
      children: [
        { name: 'Olympics', value: 35 },
        { name: 'FDA', value: 25 },
        { name: 'Food', value: 20 },
      ],
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} isCentered>
      <ModalOverlay bg={'#53535399'} />
      <ModalContent width={10000}>
        <ModalHeader>
          <Text textTransform={'capitalize'}>{activeTab}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box pb={8}>
            <EngagementAreaGraph
              categorieData={categorieData}
              topicsData={topicsData}
              topicsBubbleData={topicsBubbleData}
              isModalOpen={isOpen}
              modalGraph={activeTab === 'categories' ? 'categories' : 'topics'}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EngagementGraphModal;
