import React from 'react';

function ArrowDownIcon({ fill = '#EF6506', ...prop }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3045_137)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#FF4500"
        />
        <path
          d="M20.0002 12C20.0002 11.0316 19.2143 10.2457 18.2459 10.2457C17.7687 10.2457 17.3476 10.4281 17.0388 10.7369C15.8459 9.88074 14.1897 9.31934 12.3652 9.24916L13.1652 5.50179L15.7617 6.04916C15.7897 6.70881 16.3371 7.24214 17.0108 7.24214C17.6985 7.24214 18.2599 6.68074 18.2599 5.99302C18.2599 5.3053 17.6985 4.7439 17.0108 4.7439C16.5195 4.7439 16.0985 5.0246 15.902 5.44565L12.9967 4.82811C12.9125 4.81407 12.8283 4.82811 12.7581 4.87021C12.688 4.91232 12.6459 4.98249 12.6178 5.0667L11.7336 9.24916C9.86692 9.3053 8.19674 9.85267 6.98972 10.7369C6.68095 10.4421 6.24586 10.2457 5.78271 10.2457C4.81429 10.2457 4.02832 11.0316 4.02832 12C4.02832 12.7158 4.44937 13.3193 5.06692 13.6C5.03885 13.7685 5.02481 13.9509 5.02481 14.1334C5.02481 16.8281 8.15464 19.0035 12.0283 19.0035C15.902 19.0035 19.0318 16.8281 19.0318 14.1334C19.0318 13.9509 19.0178 13.7825 18.9897 13.6141C19.5652 13.3334 20.0002 12.7158 20.0002 12ZM8.00025 13.2492C8.00025 12.5614 8.56165 12 9.24937 12C9.93709 12 10.4985 12.5614 10.4985 13.2492C10.4985 13.9369 9.93709 14.4983 9.24937 14.4983C8.56165 14.4983 8.00025 13.9369 8.00025 13.2492ZM14.9757 16.5474C14.1195 17.4035 12.4915 17.4597 12.0143 17.4597C11.5371 17.4597 9.89499 17.3895 9.05288 16.5474C8.92657 16.4211 8.92657 16.2106 9.05288 16.0842C9.1792 15.9579 9.38972 15.9579 9.51604 16.0842C10.0494 16.6176 11.2003 16.8141 12.0283 16.8141C12.8564 16.8141 13.9932 16.6176 14.5406 16.0842C14.6669 15.9579 14.8774 15.9579 15.0038 16.0842C15.102 16.2246 15.102 16.4211 14.9757 16.5474ZM14.7511 14.4983C14.0634 14.4983 13.502 13.9369 13.502 13.2492C13.502 12.5614 14.0634 12 14.7511 12C15.4388 12 16.0002 12.5614 16.0002 13.2492C16.0002 13.9369 15.4388 14.4983 14.7511 14.4983Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3045_137">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ArrowDownIcon;
