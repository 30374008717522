import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import Papa from 'papaparse';

const tableStyles = {
  width: '100%',
  borderCollapse: 'collapse', // Collapse borders for a cleaner look
};

const thStyles = {
  textAlign: 'center',
  fontWeight: 'bold',
  backgroundColor: 'gray.100',
  padding: '8px',
};

const CSVModal = ({ isOpen, onClose, csvFile }) => {
  const [csvData, setCsvData] = useState([]);

  const parseCSV = () => {
    if (csvFile) {
      Papa.parse(csvFile, {
        complete: (result) => {
          setCsvData(result.data);
        },
        header: true, // Use the first row as header
        skipEmptyLines: true,
      });
    }
  };

  useEffect(() => {
    if (csvFile) {
      parseCSV();
    }
  }, [csvFile]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>CSV File Data</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          style={{
            padding: '0', // Remove padding for better scrolling behavior
            overflow: 'hidden', // Prevent overflowing content from spilling out of the modal
          }}
        >
          {csvData.length > 0 ? (
            <Box
              style={{
                maxHeight: '60vh', // Allow scrolling within the modal body
                overflow: 'auto', // Enable vertical scrolling
                width: '100%',
              }}
            >
              <Table
                style={{
                  minWidth: '100%', // Ensure the table takes up at least the full width
                  tableLayout: 'auto', // Allow the table to grow/shrink with content
                }}
                {...tableStyles}
              >
                <Thead>
                  <Tr>
                    {Object.keys(csvData[0]).map((key) => (
                      <Th key={key} {...thStyles}>
                        {key}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {csvData.map((row, index) => (
                    <Tr key={index}>
                      {Object.values(row).map((value, idx) => (
                        <Td textAlign={'center'} key={idx}>
                          {value}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Text>No data available in the CSV file</Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={onClose} borderRadius={'8px'}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CSVModal;
