import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  // IconButton,
  Popover,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import CheckTable from 'views/admin/dataTables/components/CheckTable';
import { columnsDataCheck } from 'views/admin/dataTables/variables/columnsData';
import MiniStatistics from 'components/card/MiniStatistics';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
// import FilterListFilledIcon from 'assets/icons/FilterListFilledIcon';
import CustomizeTablePopup from 'components/peoplePage/CustomisedTablePopUp';
import CustomSelect from 'components/peoplePage/CustomSelect';
import axios from 'axios';
import PersonalInfo from 'components/personalInfo/PersonalInfo';
import ShareInviteModal from './InviteModal/InviteModal';
import { DeleteIcon } from '@chakra-ui/icons';
import DeleteUserModel from './DeleteUser/DeleteUser';
import InviteNewPeople from 'assets/icons/InviteNewPeople';
import EyeIcon from 'assets/icons/eyeIcon';
import { useSegmentTracking } from '../../../segmentTracking';
import analytics from '../../../segmentTracking';
import { handleSessionTimeout } from 'utils/sessionManagementUtils';

const API_URL = process.env.REACT_APP_API_URL;

export default function Settings() {
  const [isCustomizeTableOpen, setIsCustomizeTableOpen] = useState(false);
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTab, setSelectedTab] = useState('All Users');
  const [isLoading, setIsLoading] = useState(false);
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const [visibleColumns, setVisibleColumns] = React.useState({
    name: true,
    email: true,
    id: true,
    location: true,
    ipLocation: true,
    lastSessions: true,
    totalSessions: true,
    status: true,
    subscriber: true,
    age: true,
    gender: true,
    race: true,
    ...(currentUser?.role === 'super_admin'
      ? { politicalPreferences: false }
      : {}),
  });
  const [usersData, setUsersData] = useState([]);
  const [totalUsersLength, setTotalUsersLength] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isInviteUsers, setIsInviteUsers] = useState(false);
  const [isSuperRole, setIsSuperRole] = useState(false);
  const toast = useToast();
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [statisticsData, setStatisticsData] = useState({});
  const [showProfile, setShowProfile] = useState(false);
  const [singleUserData, setSingleUserData] = useState({});
  // const [isLoading, setIsLoading] = useState(true);
  const [periods, setPeriods] = useState({
    peoplesPage: 'all',
    postNumber: 'all',
    activityTime: 'all',
    timeSpendOnCheckIn: 'all',
    awarenessDistribution: 'all',
    timeSpendOnCloserLook: 'all',
    evaluationSkills: 'all',
    thinking: 'all',
    categories: 'all',
  });
  const [userStatus, setUserStatus] = useState('All Users');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // eslint-disable-line
  const [currentInstitution] = useState(
    JSON.parse(localStorage.getItem('institution')),
  );
  // const { trackEvent } = useSegmentTracking();
  const periodOptions = [
    { value: 'all', display: 'All' },
    { value: 'week', display: 'Last Week' },
    { value: 'month', display: 'Last Month' },
    { value: '3months', display: 'Last 3 Months' },
    { value: '6months', display: 'Last 6 Months' },
    { value: 'year', display: 'Last Year' },
  ];

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user?.role.toLowerCase() === 'super_admin') {
      setIsSuperRole(true);
    } else {
      setIsSuperRole(false);
    }
  }, []);

  const getDisplayValue = () => {
    const selectedOption = periodOptions.find(
      (option) => option.value === periods.peoplesPage,
    );
    return selectedOption && selectedOption.display;
  };

  const statistics = [
    {
      name: 'Total users',
      value: statisticsData.totalUsers,
      change: '',
      text: '',
    },
    {
      name: 'New users',
      value: statisticsData?.newUsers?.count,
      syntax: statisticsData?.newUsers?.growth || '',
      text: statisticsData?.newUsers?.percentage
        ? `${statisticsData?.newUsers?.percentage?.toFixed(0)}% ${
            statisticsData?.newUsers?.growth === 'more'
              ? 'increase'
              : 'decrease'
          } in ${getDisplayValue()}`
        : '',
      syntaxFill: statisticsData?.newUsers?.growth
        ? `${
            statisticsData?.newUsers?.growth === 'more' ? '#25A259' : '#E02B00'
          }`
        : '',
    },
    {
      name: 'Total sessions',
      value: statisticsData?.totalSessions?.count
        ? statisticsData?.totalSessions?.count
        : '',
      syntax: statisticsData?.totalSessions?.growth
        ? `${statisticsData?.totalSessions?.growth}`
        : '',
      text: statisticsData?.totalSessions?.percentage
        ? `${
            statisticsData?.totalSessions?.percentage
              ? statisticsData?.totalSessions?.percentage?.toFixed(0)
              : 0
          }% ${
            statisticsData?.totalSessions?.growth === 'more'
              ? 'increase'
              : 'decrease'
          } in ${getDisplayValue()}`
        : '',
      syntaxFill: statisticsData?.totalSessions?.growth
        ? `${
            statisticsData?.totalSessions?.growth === 'more'
              ? '#25A259'
              : '#E02B00'
          }`
        : '',
    },
  ];

  const handleSendInvite = (data) => {
    setIsModalOpen(false);
  };

  const handleDeleteUser = async (data) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authentication token is missing.');
    }

    const emails = data.map((user) => user.email).filter(Boolean);
    const phones = data.map((user) => user.phone).filter(Boolean);
    const headers = { Authorization: `Bearer ${token}` };
    const body = { emails, phones };
    try {
      let DELETE_URL;
      if (isInviteUsers) {
        DELETE_URL = `${API_URL}/dashboard/users/deleteInvitedUser`;
      } else {
        DELETE_URL = `${API_URL}/user/profile/deleteUserByAdmin`;
      }

      const { data: responseData } = await axios.post(DELETE_URL, body, {
        headers,
      });

      if (responseData?.message) {
        toast({
          title: 'Users Deleted',
          description: responseData?.message,
          variant: 'solid',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        if (isInviteUsers) {
          await fetchAndFilterUsers();
        } else {
          await fetchAndFilterUsers(
            '/dashboard/users',
            periods.peoplesPage,
            'users',
          );
        }
      } else {
        throw new Error(responseData?.message || 'Failed to delete users.');
      }
    } catch (error) {
      console.error('Error deleting users:', error);
      toast({
        title: 'Error',
        description:
          error.response?.data?.message ||
          error.message ||
          'An error occurred while deleting users.',
        variant: 'solid',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    setSelectedRows([]);
    onCloseDelete();
  };

  const fetchAndFilterUsers = useCallback(
    async (endpoint, period, dataKey) => {
      const token = localStorage.getItem('token');
      setIsLoading(true);
      try {
        let url = `${API_URL}/dashboard/users?skip=${
          (currentPage - 1) * itemsPerPage
        }&limit=${itemsPerPage}`;
        console.log(url);
        // Add period parameter only if it's not 'all'
        if (period !== 'all') {
          url += `&period=${period}`;
        }

        // Add institution parameter
        url += `&institution=${currentInstitution?._id}`;

        // Add user status and filter logic
        if (isInviteUsers || userStatus === 'inviteUsers') {
          url = `${API_URL}/dashboard/users/getInvitedAppUsers?skip=${
            (currentPage - 1) * itemsPerPage
          }&limit=${itemsPerPage}&institution=${currentInstitution?._id}`;
        } else if (userStatus !== 'All Users') {
          url += `&subscriber=${userStatus}`;
        }
        console.log('2----', url);

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const usersArray = Array.isArray(
          response.data.data.users || response.data.data,
        )
          ? response.data.data.users || response.data.data
          : Object.values(response.data.data.users || response.data.data);

        // Additional filtering based on search term
        const filteredUsers = searchTerm
          ? usersArray.filter((user) =>
              (user.name || user.fullName || '')
                .toLowerCase()
                .includes(searchTerm.toLowerCase()),
            )
          : usersArray;

        setTotalUsersLength(
          response?.data?.data?.filteredUsersCount || filteredUsers.length,
        );
        setUsersData(usersArray);
        setFilteredUsers(filteredUsers);
        userStatus !== 'inviteUsers' && setStatisticsData(response.data.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setUsersData([]);
        setFilteredUsers([]);
        handleSessionTimeout(error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      currentInstitution,
      currentPage,
      itemsPerPage,
      userStatus,
      isInviteUsers,
      searchTerm,
    ],
  );

  useEffect(() => {}, [usersData]);

  // useEffect(() => {
  //   if (!isInviteUsers) {
  //     fetchAndFilterUsers('/dashboard/users', periods.peoplesPage, 'users');
  //   }
  // }, [fetchAndFilterUsers, periods.peoplesPage, isInviteUsers, itemsPerPage]);

  useEffect(() => {
    fetchAndFilterUsers('/dashboard/users', periods.peoplesPage, 'users');
  }, [fetchAndFilterUsers, periods.peoplesPage, currentPage, itemsPerPage]);

  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([
        fetchAndFilterUsers(
          'dashboard/users/getPostsNumbers',
          periods?.peoplesPage,
        ),
      ]);
    };

    fetchAllData();
  }, [fetchAndFilterUsers, periods, itemsPerPage]);

  const handleUserStatusChange = (newStatus) => {
    setUserStatus(newStatus);
    setIsInviteUsers(newStatus === 'inviteUsers');
    setCurrentPage(1);
  };

  const mapUserData = useCallback((users) => {
    if (!Array.isArray(users) || users.length === 0) {
      return [{ message: 'No records found' }];
    }

    return users.map((user) => ({
      name: user.name || user.fullName || '',
      email: user.email || '',
      phoneNumber: user.phone || '',
      id: user.uid || '',
      clerkUserId: user.clerkUserId || '',
      userId: user._id,
      location: user.location
        ? `${user.location.city}, ${user.location.state}`
        : '',
      ipLocation:
        user?.ipState && user?.ipCountry
          ? `${user.ipState}, ${user.ipCountry}`
          : '',
      lastSession: user?.lastSession
        ? new Date(user.lastSession).toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })
        : '',
      totalSessions: user?.totalSessions || 0,
      status: user.verificationStatus || '',
      subscriber: user.subscriber || '',
      age: user.birthDate ? calculateAge(user.birthDate) : '',
      gender: user.gender ? user.gender.name : '',
      race: user.racialIdentity ? user.racialIdentity.name : '',
      state: user?.state || user?.ipState || user?.location?.state,
      country: user?.country || user?.ipCountry || user?.location?.country,
      politicalPreferences: user.politicalPreference
        ? user.politicalPreference.name
        : '',
      institution: user?.institution || '',
    }));
  }, []);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }
    return age.toString();
  };

  const handlePeriodChange = (dataKey, newPeriod) => {
    if (!isInviteUsers) {
      setPeriods((prevPeriods) => ({
        ...prevPeriods,
        [dataKey]: newPeriod,
      }));
    }
  };

  const handleUsersTab = (tabType) => {
    handleUserStatusChange(tabType);
    setSelectedTab(tabType);
    // Track users tab change
    analytics.track('people:userstab:clicked', {
      email: currentUser?.email,
      userRole: currentUser?.role,
      tab: tabType,
    });
  };

  return (
    <Box
      pt={{ base: '130px', md: '80px', xl: '35px' }}
      style={{ marginTop: '5px' }}
      position={'relative'}
    >
      {showProfile === false ? (
        <>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Text
              fontSize={'28px'}
              fontWeight={'bold'}
              color={'#222222'}
              lineHeight={'32px'}
              // onClick={() => trackEvent('People Page Clicked')}
            >
              People
            </Text>
            <Box
              width={'161px'}
              height={'32px'}
              borderRadius={'8px'}
              bg={'#EF6506'}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              padding={'6px 8px 6px 8px'}
              gap={'8px'}
              cursor={'pointer'}
              onClick={() => {
                onOpen();
                // Track invite new people (clicked)
                analytics.track('people:invitenewpeople:clicked', {
                  email: currentUser.email,
                  userRole: currentUser?.role,
                });
              }}
              justifyContent={'center'}
            >
              {/* <ShareAndroidIcon /> */}
              <InviteNewPeople fill={'#FFFDFB'} />
              <Text fontWeight={'700'} fontSize={'14px'} color="white">
                Invite new people
              </Text>
            </Box>
          </Box>
          <ShareInviteModal
            isOpen={isOpen}
            onClose={onClose}
            onSendInvite={handleSendInvite}
          />
          <SimpleGrid mt={'4'} spacing={{ base: '10px', xl: '10px' }}>
            <SimpleGrid
              width={'100%'}
              columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
              gap="8px"
              bg={'#FFFDFB'}
              justifyContent={'space-between'}
              className="miniStatisticsContainer"
            >
              {statistics?.map((stat, index) => (
                <MiniStatistics
                  key={index}
                  name={stat?.name}
                  syntax={stat?.syntax}
                  syntaxFill={stat?.syntaxFill}
                  text={stat?.text}
                  value={stat?.value?.count || stat?.value || 0}
                  change={stat?.change}
                />
              ))}
            </SimpleGrid>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mt={'12px'}
            >
              <Flex alignItems="center" gap="10px">
                {/* <CustomSelect
                  options={usersOptions}
                  width="150px"
                  value={userStatus}
                  onChange={(newStatus) => handleUserStatusChange(newStatus)}
                  placeholder={'All Users'}
                /> */}
                <Flex
                  gap={'24px'}
                  marginTop={4}
                  marginLeft={5}
                  fontWeight={600}
                  marginBottom={0.1}
                >
                  <Text
                    cursor="pointer"
                    onClick={() => handleUsersTab('All Users')}
                    borderBottom={
                      selectedTab === 'All Users'
                        ? '2.5px solid #EF6506'
                        : 'none'
                    }
                    color={selectedTab === 'All Users' ? '#EF6506' : 'none'}
                    fontSize={'14px'}
                  >
                    All Users
                  </Text>
                  <Text
                    cursor="pointer"
                    onClick={() => handleUsersTab('Active')}
                    borderBottom={
                      selectedTab === 'Active' ? '2.5px solid #EF6506' : 'none'
                    }
                    color={selectedTab === 'Active' ? '#EF6506' : 'none'}
                    fontSize={'14px'}
                  >
                    Subscribers
                  </Text>
                  <Text
                    cursor="pointer"
                    onClick={() => handleUsersTab('Trial')}
                    borderBottom={
                      selectedTab === 'Trial' ? '2.5px solid #EF6506' : 'none'
                    }
                    color={selectedTab === 'Trial' ? '#EF6506' : 'none'}
                    fontSize={'14px'}
                  >
                    Trial
                  </Text>
                  <Text
                    cursor="pointer"
                    onClick={() => handleUsersTab('Free')}
                    borderBottom={
                      selectedTab === 'Free' ? '2.5px solid #EF6506' : 'none'
                    }
                    color={selectedTab === 'Free' ? '#EF6506' : 'none'}
                    fontSize={'14px'}
                  >
                    Free
                  </Text>
                  <Text
                    cursor="pointer"
                    onClick={() => handleUsersTab('inviteUsers')}
                    borderBottom={
                      selectedTab === 'inviteUsers'
                        ? '2.5px solid #EF6506'
                        : 'none'
                    }
                    color={selectedTab === 'inviteUsers' ? '#EF6506' : 'none'}
                    fontSize={'14px'}
                  >
                    Invited
                  </Text>
                </Flex>
              </Flex>
              <Flex alignItems="center" gap="10px" position="relative">
                <SearchBar
                  placeholder="Search by name"
                  borderRadius="8px"
                  width="200px"
                  // style={{
                  //   height:'32px', padding:0}}

                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onFocus={() => {
                    // Track search by name
                    analytics.track('people:searchbyname:clicked', {
                      email: currentUser.email,
                      userRole: currentUser?.role,
                    });
                  }}
                />
                <CustomSelect
                  options={periodOptions}
                  placeholder="6 months"
                  width="150px"
                  value={periods.peoplesPage}
                  onChange={(newPeriod) => {
                    handlePeriodChange('peoplesPage', newPeriod);
                    // Track period change
                    analytics.track('people:periodchange:clicked', {
                      email: currentUser.email,
                      userRole: currentUser?.role,
                      period: newPeriod,
                    });
                  }}
                />
                {/* Delete Button */}
                {isSuperRole && (
                  <>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      border="1px solid #E7E7E7"
                      padding={3}
                      borderRadius="8px"
                      height={'32px'}
                      // width={'32px'}
                      opacity={selectedRows?.length > 0 ? 1 : 0.5}
                      cursor={
                        selectedRows?.length > 0 ? 'pointer' : 'not-allowed'
                      }
                      _disabled={selectedRows?.length > 0 ? false : true}
                      _hover={
                        selectedRows?.length > 0
                          ? {}
                          : {
                              backgroundColor: '#f5f5f5',
                              border: '1px solid #EF6506',
                            }
                      }
                      onClick={() => {
                        selectedRows?.length > 0 && onOpenDelete();
                        // Track delete users
                        analytics.track('people:deleteusers:clicked', {
                          email: currentUser.email,
                          userRole: currentUser?.role,
                        });
                      }}
                    >
                      {<DeleteIcon color={'#EF6506'} />}
                    </Box>
                    <DeleteUserModel
                      isOpen={isOpenDelete}
                      onClose={onCloseDelete}
                      onDeleteUsers={() => handleDeleteUser(selectedRows)}
                    />
                  </>
                )}
                <Box position="relative">
                  <Popover>
                    <PopoverTrigger>
                      {/* <Button
                        border="1px solid #E7E7E7"
                        borderRadius="8px"
                        leftIcon={<SettingIcon />}
                        bg="none"
                        _hover={{ bg: '#FFF8F3' }}
                        _active={{ bg: '#FFF8F3' }}
                        onClick={() =>
                          setIsCustomizeTableOpen(!isCustomizeTableOpen)
                        }
                      >
                        Customize table
                      </Button> */}
                      <Button
                        border={'1px solid #E7E7E7'}
                        borderRadius="8px"
                        bg="none"
                        _hover={{ bg: '#FFF8F3', border: '1px solid #EF6506' }}
                        _active={{ bg: '#FFF8F3' }}
                        onClick={() => {
                          setIsCustomizeTableOpen(!isCustomizeTableOpen);
                          // Track customize table
                          analytics.track('people:customizetable:clicked', {
                            email: currentUser.email,
                            userRole: currentUser?.role,
                          });
                        }}
                        height={'32px'}
                        padding={'0'}
                      >
                        {/* <SettingIcon /> */}
                        <EyeIcon />
                      </Button>
                    </PopoverTrigger>
                    <CustomizeTablePopup
                      isOpen={isCustomizeTableOpen}
                      onClose={() =>
                        setIsCustomizeTableOpen(!isCustomizeTableOpen)
                      }
                      visibleColumns={visibleColumns}
                      setVisibleColumns={setVisibleColumns}
                      setSingleUserData={setSingleUserData}
                    />
                  </Popover>
                </Box>
                {/* <IconButton
                  border="1px solid #E7E7E7"
                  borderRadius="8px"
                  aria-label="Filter"
                  icon={<FilterListFilledIcon />}
                  bg="none"
                  _hover={{ bg: '#FFF8F3' }}
                  _active={{ bg: '#FFF8F3' }}
                /> */}
              </Flex>
            </Flex>
            <CheckTable
              key={JSON.stringify(filteredUsers)}
              columnsData={columnsDataCheck}
              visibleColumns={visibleColumns}
              tableData={mapUserData(filteredUsers)}
              setSingleUserData={setSingleUserData}
              setShowProfile={setShowProfile}
              totalUsersLength={totalUsersLength}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setItemsPerPage={setItemsPerPage}
              itemsPerPage={itemsPerPage}
              isLoading={isLoading}
            />
          </SimpleGrid>
        </>
      ) : (
        <>
          {singleUserData && (
            <PersonalInfo
              singleUserData={singleUserData}
              setShowProfile={setShowProfile}
            />
          )}
        </>
      )}
    </Box>
  );
}
