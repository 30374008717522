import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  // Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  ChakraProvider,
  Select,
  useDisclosure,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import analytics from '../../../../segmentTracking';
import CopyPasteIcon from 'assets/icons/CopyPasteIcon';
import CustomSelect from 'components/peoplePage/CustomSelect';
import axios from 'axios';
import { useGate } from 'statsig-react';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import { Switch } from '../../../../components/Switch/index';
import { Input } from '../../../../components/Input/index';
import api from '../../../../utils/api';

const Team = () => {
  const { value: toggleShareLink } = useGate('settings_display_share_link'); // useGate inside a custom hook
  const [copyLink, setCopyLink] = useState('');
  const [sendInviteEmail, setSendInvite] = useState('');
  const [shareLinkSwitch, setShareLinkSwitch] = useState(true);
  const inputRef = useRef(null);
  const [checkValidEmail, setCheckValidEmail] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  // const [selectedPeriod, setSelectedPeriod] = useState('All Period');
  // const [inviteSendViaEmail, setInviteSendViaEmail] = useState(false);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false); // eslint-disable-line
  const [teamMember, setTeamMember] = useState([]);
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));
  const token = localStorage.getItem('token');
  const [editUser, setEditUser] = useState({});
  const usersOptions = [
    { value: 'all', display: 'All members' },
    { value: 'admin', display: 'Admin' },
    { value: 'member', display: 'Member' },
  ];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [role, setRole] = useState('');

  const handleChange = (event) => {
    // Track role selection
    analytics.track('settings:team:role:selected', {
      role: event.target.value,
      email: currentUser.email,
      userRole: currentUser?.role,
    });
    setRole(event.target.value);
  };

  const handleCopy = () => {
    inputRef.current.select();
    navigator.clipboard.writeText(copyLink);
  };

  const handleSelectedStatusChange = (value) => {
    // Track status filter selection
    analytics.track('settings:team:members:status:filtered', {
      status: value,
      email: currentUser.email,
      userRole: currentUser?.role,
    });
    setSelectedStatus(value);
  };

  const handleInviteSendViaEmail = async (e) => {
    e.preventDefault();

    // Track send invite action
    analytics.track('settings:team:invite:sent', {
      email: sendInviteEmail,
      role: role,
      senderEmail: currentUser.email,
      userRole: currentUser?.role,
    });

    setIsLoading(true);

    try {
      // Ensure you are getting the correct token
      const currentInstitution = JSON.parse(
        localStorage.getItem('institution'),
      );
      const headers = {
        Authorization: `Bearer ${token}`, // Pass the correct token here
      };

      let body = {};
      if (sendInviteEmail) {
        body.email = sendInviteEmail;
      }
      body.institution = currentInstitution?._id;
      // console.log('body ----->', body);
      if (role) {
        body.role = role;
      }

      const response = await api.post(`/dashboard/users/inviteUser`, body);

      // console.log('RESPONSE DATA  =>', response.data);

      const { message } = response.data;

      toast({
        title: 'Invite Sent',
        description: message || 'Invitation has been sent successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setRole('');
      setSendInvite('');
    } catch (error) {
      console.log('invite error', error);

      toast({
        title: 'Error',
        description:
          error.response?.data?.message ||
          'An error occurred while sending the invite.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
    // setInviteSendViaEmail(true);
  };

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const fetchTeamMembers = async () => {
    try {
      const token = localStorage.getItem('token');
      const { _id } = JSON.parse(localStorage.getItem('institution'));

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await api.get(
        `/dashboard/teams?role=&skip=0&limit=10&institution=${_id}`,
      );

      if (response?.data?.success) {
        setTeamMember(response?.data?.data);
      } else {
        console.log('Network error');
      }
    } catch (error) {
      console.log(`Error -> ${error.message}`);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  useEffect(() => {
    setCheckValidEmail(validateEmail(sendInviteEmail));
  }, [sendInviteEmail]);

  async function handleEditName(name) {
    // Track edit name action
    analytics.track('settings:team:member:name:submitted', {
      editorEmail: currentUser.email,
      memberEmail: editUser.email,
      userRole: currentUser?.role,
      email: currentUser?.email,
    });

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await api.patch(`/dashboard/teams/updateUser`, {
        name: name,
        userId: editUser?._id,
      });

      if (response?.data?.success) {
        fetchTeamMembers();
      } else {
        console.log('Network error');
      }
    } catch (error) {
      console.log(`Error -> ${error.message}`);
    }
  }

  async function handleDeleteAdmin() {
    // Track edit name action
    analytics.track('settings:team:member:delete:submitted', {
      editorEmail: currentUser.email,
      memberEmail: editUser.email,
      userRole: currentUser?.role,
      email: currentUser?.email,
    });

    try {
      const response = await api.delete(
        `/dashboard/teams/deleteAdmin?id=${editUser?._id}`,
      );

      if (response?.data?.success) {
        toast({
          title: 'Admin Deleted Successfully',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        setTimeout(() => {
          fetchTeamMembers();
        }, 2000);
      } else {
        console.log('Network error');
      }
    } catch (error) {
      console.log(`Error -> ${error.message}`);
    }
  }

  return (
    <Box width={'65%'} margin={'24px auto 48px'}>
      {/* Invite Members */}
      <Box paddingBottom={'48px'} borderBottom={'1px solid #E7E7E7'}>
        <Box>
          <Text fontSize={'20px'} fontWeight={'700'} lineHeight={'24px'}>
            Invite members
          </Text>
        </Box>
        {toggleShareLink && (
          <>
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              margin={'12px 0'}
            >
              <Box>
                <Text>Share this link to invite others to Twella</Text>
              </Box>

              <Box>
                <Switch
                  checked={shareLinkSwitch}
                  onCheckedChange={() => setShareLinkSwitch(!shareLinkSwitch)}
                  // mr="2"
                  // sx={{
                  //   '& .chakra-switch__track': {
                  //     backgroundColor: '#C2C2C2',
                  //   },
                  //   '& .chakra-switch__track[data-checked]': {
                  //     backgroundColor: '#EF6506',
                  //   },
                  // }}
                />
              </Box>
            </Flex>
            <Flex>
              <Box flexBasis={'90%'}>
                <Input
                  ref={inputRef}
                  name="copyLink"
                  id="copyLink"
                  value={copyLink}
                  onChange={(e) => setCopyLink(e.target.value)}
                  placeholder="https://twella.app/"
                  style={{
                    outline: 'none',
                    // border: 'none',
                    border: '1.1px solid #E7E7E7',
                    background: 'transparent',
                    padding: '5px 12px',
                    width: '100%',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                />
              </Box>
              <Flex
                bg={'#EF6506'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={'0 8px'}
                flexBasis={'10%'}
                borderRightRadius={'8px'}
                onClick={handleCopy}
                cursor={'pointer'}
              >
                <Box>
                  <CopyPasteIcon />
                </Box>
                <Box>
                  <Text
                    color={'#FFFDFB'}
                    fontWeight={'700'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    marginLeft={'2px'}
                  >
                    Copy
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </>
        )}

        {currentUser?.role !== 'member' ? (
          <>
            <Box pt={'16px'}>
              <Text>Send Twella email invites</Text>
            </Box>
            {/* Send invite input  */}
            <Flex pt={'8px'} gap="4">
              <Box flexBasis={'60%'}>
                <Input
                  // ref={inputRef}
                  name="sendInviteEmail"
                  id="sendInviteEmail"
                  type="email"
                  value={sendInviteEmail}
                  onFocus={() => {
                    // Track email input focus (clicked)
                    analytics.track('settings:team:invite:email:clicked', {
                      email: currentUser.email,
                      inputType: 'invite_email',
                      userRole: currentUser?.role,
                    });
                  }}
                  onChange={(e) => setSendInvite(e.target.value)}
                  placeholder="mymail@gmail.com"
                  style={{
                    outline: 'none',
                    // border: 'none',
                    border: '1.1px solid #E7E7E7',
                    background: 'transparent',
                    padding: '5px 12px',
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Box flexBasis={'25%'}>
                <ChakraProvider>
                  <Box>
                    <Select
                      required={true}
                      h={'35px'}
                      placeholder="Select Role"
                      onChange={handleChange}
                      value={role}
                    >
                      <option value="admin">Admin</option>
                      <option value="member">Member</option>
                    </Select>
                  </Box>
                </ChakraProvider>
              </Box>
              <Flex
                bg={checkValidEmail && role ? '#EF6506' : '#EF650680'}
                justifyContent="center"
                alignItems="center"
                padding="0 8px"
                flexBasis="15%"
                borderRightRadius="8px"
                onClick={
                  checkValidEmail && role ? handleInviteSendViaEmail : undefined
                }
                cursor={checkValidEmail && role ? 'pointer' : 'not-allowed'}
                opacity={checkValidEmail && role ? 1 : 0.5}
                pointerEvents={checkValidEmail && role ? 'auto' : 'none'}
              >
                {/* <Box>
                  <ShareAndroidIcon />
                </Box> */}
                <Box>
                  <Text
                    color="#FFFDFB"
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="20px"
                    ml="4px"
                  >
                    Send invite
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </>
        ) : (
          ''
        )}
      </Box>

      {/* Manage Members */}
      <Box marginTop={'32px'}>
        <Box>
          <Text fontSize={'20px'} fontWeight={'700'} lineHeight={'24px'}>
            Manage members
          </Text>
        </Box>

        <Box mt={'24px'}>
          <Flex mb={4}>
            <Box mr={'8px'}>
              <Input
                placeholder="Search by name"
                value={searchTerm}
                onFocus={() => {
                  // Track search input focus (clicked)
                  analytics.track('settings:team:search:clicked', {
                    email: currentUser.email,
                    inputType: 'search_name',
                    userRole: currentUser?.role,
                  });
                }}
                className="TextInput"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>

            <Box mr={'8px'}>
              <CustomSelect
                options={usersOptions}
                width="150px"
                placeholder={'All members'}
                value={selectedStatus}
                onChange={(newStatus) => handleSelectedStatusChange(newStatus)}
              />
            </Box>
          </Flex>
          <Box border={'1px solid #E7E7E7'} borderRadius={'12px'}>
            <Table>
              <Thead borderBottomWidth={2} borderBottomColor="#E7E7E7">
                <Tr>
                  <Th>Name</Th>
                  <Th borderLeft={'1px solid #E7E7E7'}>
                    <Text>Email</Text>
                  </Th>
                  <Th borderLeft={'1px solid #E7E7E7'}>Status</Th>
                  {currentUser?.role === 'super_admin' ? (
                    <Th borderLeft={'1px solid #E7E7E7'}>Action</Th>
                  ) : (
                    ''
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {teamMember
                  ?.filter((member) => {
                    // Check if the search term is matched
                    let matchesSearchTerm = true;
                    if (searchTerm) {
                      matchesSearchTerm = member?.fullName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase());
                    }
                    // Check if the selectedStatus matches the member's role
                    const matchesStatus =
                      selectedStatus === 'all' ||
                      member?.role === selectedStatus;

                    // Return members that match both the search term and status
                    return matchesSearchTerm && matchesStatus;
                  })
                  .map((member, index) => {
                    const capSuperAdmin = member?.role
                      .split('_')
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase(),
                      )
                      .join(' ');

                    return (
                      <Tr key={index}>
                        <Td borderColor="#E7E7E7">{member?.fullName}</Td>
                        <Td borderColor="#E7E7E7">{member?.email}</Td>
                        <Td borderColor="#E7E7E7">{capSuperAdmin}</Td>
                        {currentUser?.role === 'super_admin' ? (
                          <Td borderColor="#E7E7E7">
                            <EditIcon
                              cursor={'pointer'}
                              onClick={() => {
                                // Track edit action click
                                analytics.track(
                                  'settings:team:member:action:clicked',
                                  {
                                    editorEmail: currentUser.email,
                                    memberEmail: member.email,
                                    userRole: currentUser?.role,
                                    email: currentUser?.email,
                                  },
                                );
                                setEditUser(member);
                                onOpen();
                              }}
                            />
                            <DeleteIcon
                              cursor={'pointer'}
                              marginLeft={'5px'}
                              color={'red'}
                              onClick={() => {
                                // Track delete action click
                                analytics.track(
                                  'settings:team:member:delete:clicked',
                                  {
                                    editorEmail: currentUser.email,
                                    memberEmail: member.email,
                                    userRole: currentUser?.role,
                                    email: currentUser?.email,
                                  },
                                );
                                onDeleteOpen();
                                setEditUser(member);
                              }}
                            />
                          </Td>
                        ) : (
                          ''
                        )}
                      </Tr>
                    );
                  })}

                {teamMember?.filter((member) => {
                  const matchesSearchTerm = member?.name
                    ?.toLowerCase()
                    .includes(searchTerm?.toLowerCase());

                  const matchesStatus =
                    selectedStatus === 'all' || member?.role === selectedStatus;

                  return matchesSearchTerm && matchesStatus;
                }).length === 0 && (
                  <Tr>
                    <Td colSpan="3" textAlign="center" borderColor="#E7E7E7">
                      No records found
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Box>
      <EditModal
        isOpen={isOpen}
        onClose={onClose}
        data={editUser}
        handleEditName={handleEditName}
      />
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        handleDelete={handleDeleteAdmin}
      />
    </Box>
  );
};

export default Team;
