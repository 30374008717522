import {
  Box,
  Flex,
  Text,
  // useColorModeValue,
  useMediaQuery,
  Avatar,
} from '@chakra-ui/react';
// import { ChevronDownIcon } from '@chakra-ui/icons';
import LeftArrow from 'assets/icons/LeftArrow';
import '../../assets/css/PersonalInfo.css';
import React, { useCallback, useEffect, useState } from 'react';
import LocationIcon from 'assets/icons/Location';
import UserId from 'assets/icons/UserId';
import MessageIcon from 'assets/icons/MessageIcon';
// import Card from 'components/card/Card.js';
import PhoneIcon from 'assets/icons/PhoneIcon';
// import LineGraph from './PostNumberBarGraph';
import ActivityTimeBarGraph from './ActivityTimeBarGraph';
import PostNumberBarGraph from './PostNumberBarGraph';
import TimeSpendOnCheckInBarGraph from './TimeSpendOnCheckInBarGraph';
import AwarnessDistributionBarGraph from './AwarnessDistributionBarGraph';
import TimeSpendOnCloserLookBarGraph from './TimeSpendOnCloserLookBarGraph';
import EvaluationSkillsBarGraph from './EvaluationSkillsBarGraph';
import ThinkingBarGraph from './ThinkingBarGraph';
// import EngagementBarGraph from './LineGraph';
import EngagementCategories from './EngagementCategories';
// import PieChart from './PieChart';
import axios from 'axios';
import AppActivityBarGraph from './AppActivityBarGraph';
import { useGate } from 'statsig-react';
import { PiCityLight } from 'react-icons/pi';
import analytics from 'segmentTracking';
// const API_URL = 'https://api.qa.twella.app/api/v1';
const API_URL = process.env.REACT_APP_API_URL;

const ThinkingBarGraphData = [
  { xAxis: 10, value1: 'Low', value2: 'High' },
  { xAxis: 20, value1: 'High', value2: 'Low' },
  { xAxis: 30, value1: 'Low', value2: 'Medium' },
  { xAxis: 40, value1: 'Medium', value2: 'High' },
  { xAxis: 50, value1: 'Medium', value2: 'Low' },
  { xAxis: 60, value1: 'High', value2: 'Medium' },
  { xAxis: 70, value1: 'Low', value2: 'Medium' },
  { xAxis: 80, value1: 'Low', value2: 'High' },
  { xAxis: 90, value1: 'Medium', value2: 'Low' },
  { xAxis: 100, value1: 'Low', value2: 'Medium' },
];

const topicsData = [
  { name: '2024 Elections', value: 40 },
  { name: 'Fitness', value: 21 },
  { name: 'Olympics 2024', value: 14 },
  { name: 'AI', value: 10 },
  { name: 'Food', value: 8 },
  { name: 'Climate', value: 7 },
];

const topicsBubbleData = [
  {
    name: 'Media',
    children: [
      { name: '2024 Elections', value: 40 },
      { name: 'Trump', value: 30 },
      { name: 'Ukraine', value: 20 },
      { name: 'UK Riots', value: 25 },
      { name: 'NYT', value: 28 },
    ],
  },
  {
    name: 'Tech',
    children: [
      { name: 'AI', value: 35 },
      { name: 'Climate Tech', value: 30 },
      { name: 'Nvidia', value: 25 },
      { name: 'VC', value: 20 },
      { name: 'X', value: 15 },
    ],
  },
  {
    name: 'Health',
    children: [
      { name: 'Olympics', value: 35 },
      { name: 'FDA', value: 25 },
      { name: 'Food', value: 20 },
    ],
  },
];

const PersonalInfo = ({ singleUserData, setShowProfile }) => {
  const userInfo = {
    name: singleUserData?.name,
    institution: singleUserData?.institution,
  };
  const { value: peoplesThinkingBargraph } = useGate(
    'peoples_thinking_bargraph',
  );

  const fontColor = '#222222';
  const fontColor1 = '#676666';
  // const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [isLargerThan850] = useMediaQuery('(min-width: 850px)');
  // const [error, setError] = useState(null); // Error state
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [periods, setPeriods] = useState({
    appActivity: 'month',
    postNumber: 'month',
    activityTime: 'month',
    timeSpendOnCheckIn: 'month',
    awarnessDistribution: 'month',
    timeSpendOnCloserLook: 'month',
    evaluationSkills: 'month',
    thinking: 'month',
    categories: 'month',
  });
  const [graphData, setGraphData] = useState({
    appActivity: null,
    postNumber: null,
    activityTime: null,
    timeSpendOnCheckIn: null,
    awarnessDistribution: null,
    timeSpendOnCloserLook: null,
    evaluationSkills: null,
    thinking: null,
    categories: null,
  });

  const periodOptions = [
    { value: 'all', display: 'All' },
    { value: 'week', display: 'Last Week' },
    { value: 'month', display: 'Last Month' },
    { value: '3months', display: 'Last 3 Months' },
    { value: '6months', display: 'Last 6 Months' },
    { value: 'year', display: 'Last Year' },
  ];

  const fetchData = useCallback(
    async (endpoint, period, dataKey, chartData) => {
      try {
        const token = localStorage.getItem('token');
        const currentInstitution = JSON.parse(
          localStorage.getItem('institution'),
        );

        const params = new URLSearchParams({
          userId: singleUserData?.userId || '',
          institution: currentInstitution?._id || '',
        });

        if (period !== 'all') {
          params.append('period', period);
        }

        const url = chartData
          ? `${API_URL}/${endpoint}?${params.toString()}&chartData=${chartData}`
          : `${API_URL}/${endpoint}?${params.toString()}`;
        console.log('url ------->', url);

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setGraphData((prevData) => ({
          ...prevData,
          [dataKey]: response.data.data,
        }));
      } catch (error) {
        // setError(error);
        console.error(`Error fetching ${dataKey} data:`, error);
      }
    },
    [], // eslint-disable-line
  );

  const [selectedOption, setSelectedOption] = useState({
    timeSpendOnCloserLook: { value: 'avg', label: 'Average' },
    evaluationSkills: { value: 'avg', label: 'Average' },
    awarenessDistribution: { value: 'avg', label: 'Average' },
    timeSpendOnCheckIn: { value: 'avg', label: 'Average' },
    activityTime: { value: 'total', label: 'Total' },
    appActivity: { value: 'total', label: 'Total' },
  });

  useEffect(() => {
    fetchData(
      'dashboard/users/appActivity',
      periods.appActivity,
      'appActivity',
      selectedOption?.appActivity?.value,
    );
    fetchData(
      'dashboard/users/getPostsNumbers',
      periods.postNumber,
      'postNumber',
    );
    fetchData(
      'dashboard/users/getActivityTime',
      periods.activityTime,
      'activityTime',
      selectedOption?.activityTime?.value,
    );
    fetchData(
      'dashboard/users/getTimeSpentOnCheckIn',
      periods.timeSpendOnCheckIn,
      'timeSpendOnCheckIn',
      selectedOption?.timeSpendOnCheckIn?.value,
    );
    fetchData(
      'dashboard/users/getAwarenessDistribution',
      periods.awarnessDistribution,
      'awarnessDistribution',
      selectedOption?.awarenessDistribution?.value,
    );
    fetchData(
      'dashboard/users/getTimeSpentOnCloserLook',
      periods.timeSpendOnCloserLook,
      'timeSpendOnCloserLook',
      selectedOption?.timeSpendOnCloserLook?.value,
    );
    fetchData(
      'dashboard/users/getEvaluationSkills',
      periods.evaluationSkills,
      'evaluationSkills',
      selectedOption?.evaluationSkills?.value,
    );
    fetchData(
      'dashboard/users/getCategoriesData',
      periods.categories,
      'categories',
    );
    // fetchData('dashboard/users/thinking', periods.thinking, 'thinking');
  }, [periods, singleUserData?.userId, selectedOption]); // eslint-disable-line

  const handlePeriodChange = (key, newPeriod) => {
    
    analytics.track(`people:${key === "evaluationSkills" ? "analyticalskills" : key}:selected:${newPeriod}`, {
      email: currentUser.email,
      userRole: currentUser?.role,
      period : newPeriod,
      graphName : key
    });

    setPeriods((prevPeriods) => ({
      ...prevPeriods,
      [key]: newPeriod,
    }));
  };

  return (
    <Box>
      {/* Single Page Info Header Section START From Here */}
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        flexDirection={'column'}
      >
        <Box
          mb={'8px'}
          cursor={'pointer'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={() =>{
            setShowProfile(false);
            analytics.track(`people:back:clicked`, {
              email: currentUser.email,
              userRole: currentUser?.role,
            });
          }}
        >
          <LeftArrow />
          <Text
            color={fontColor}
            textTransform={'capitalize'}
            fontWeight={'500'}
            fontSize={'14px'}
            lineHeight={'20px'}
          >
            back
          </Text>
        </Box>
        <Box>
          <Text
            fontSize={'28px'}
            fontWeight={'700'}
            color={fontColor}
            lineHeight={'32px'}
            textTransform={'capitalize'}
          >
            {singleUserData?.name}
          </Text>
        </Box>
      </Box>
      {/* Single Page Info Header Section END From Here */}

      {/* Single Page Info Personal Section START From Here */}

      <Box margin={'24px 0 40px'}>
        <Text
          color={fontColor}
          fontSize={'20px'}
          lineHeight={'24px'}
          fontWeight={'700'}
          textTransform={'capitalize'}
          margin={'24px 0 12px'}
        >
          personal
        </Text>
        <Flex
          gap={isLargerThan850 ? '2%' : '5%'}
          flexDirection={isLargerThan850 ? 'row' : 'column'}
          className="singlePersonalData"
          overflow={'hidden'}
          width={'100%'}
        >
          {/* Personal Info Graph */}
          <Box
            border={'1px'}
            borderRadius={'12px'}
            borderColor={'#E7E7E7'}
            width={isLargerThan850 ? '48%' : '100%'}
            mb={isLargerThan850 ? '' : '18px'}
          >
            <Box className="personalDataCard">
              <Box display={'flex'} alignItems={'center'}>
                {/* <Image src={image} boxSize="40px" borderRadius="full" mr="3" /> */}

                <Avatar
                  src="https://bit.ly/broken-link"
                  height={'32px'}
                  width={'32px'}
                  mr="3"
                  borderRadius="full"
                  boxSize="40px"
                />
                <Text
                  color={fontColor}
                  fontSize={'16px'}
                  lineHeight={'22px'}
                  fontWeight={'700'}
                  textTransform={'capitalize'}
                >
                  {singleUserData?.name}
                </Text>
              </Box>
              <Text
                fontSize="14px"
                fontWeight="500"
                color={
                  singleUserData?.subscriber === 'Active'
                    ? '#25A259'
                    : singleUserData?.subscriber === 'Trial'
                    ? '#FA8638'
                    : '#676666'
                }
                bg={
                  singleUserData?.subscriber === 'Active'
                    ? '#25A25914'
                    : singleUserData?.subscriber === 'Trial'
                    ? '#FFCC951F'
                    : '#67666614'
                }
                padding={'1px 12px 1px 12px'}
                borderRadius={'12px'}
                border={
                  singleUserData?.subscriber === 'Active'
                    ? '1px solid #25A259'
                    : singleUserData?.subscriber === 'Trial'
                    ? '1px solid #FA8638'
                    : '1px solid #676666'
                }
              >
                {singleUserData?.subscriber}
              </Text>
            </Box>

            {/* Line */}
            <Box bg={'#E7E7E7'} height={'1px'}>
              <Text display={'none'}>line</Text>
            </Box>
            {/* Line */}

            <Box display={'flex'} height={'100%'}>
              <Box
                flexBasis={'50%'}
                borderRight={'1px'}
                borderColor={'#E7E7E7'}
                padding={'16px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                  margin={'8px 0'}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <MessageIcon />
                    <Text
                      flexBasis={'35%'}
                      marginLeft={'12px'}
                      textTransform={'capitalize'}
                      color={fontColor}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                    >
                      email
                    </Text>
                  </Box>
                  <Text
                    color={fontColor1}
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {singleUserData?.email}
                  </Text>
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                  margin={'8px 0'}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <PhoneIcon />
                    <Text
                      flexBasis={'35%'}
                      marginLeft={'12px'}
                      textTransform={'capitalize'}
                      color={fontColor}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                    >
                      Phone
                    </Text>
                  </Box>
                  <Text
                    color={fontColor1}
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {singleUserData?.phoneNumber}
                  </Text>
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                  margin={'8px 0'}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <LocationIcon />
                    <Text
                      flexBasis={'35%'}
                      marginLeft={'12px'}
                      textTransform={'capitalize'}
                      color={fontColor}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                    >
                      location
                    </Text>
                  </Box>

                  <Text
                    color={fontColor1}
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {singleUserData?.location}
                  </Text>
                </Box>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                  margin={'8px 0'}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <UserId />
                    <Text
                      flexBasis={'35%'}
                      marginLeft={'12px'}
                      textTransform={'capitalize'}
                      color={fontColor}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                    >
                      id
                    </Text>
                  </Box>
                  <Text
                    color={fontColor1}
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {singleUserData?.id}
                  </Text>
                </Box>

                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                  margin={'8px 0'}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <PiCityLight />
                    <Text
                      flexBasis={'35%'}
                      marginLeft={'12px'}
                      textTransform={'capitalize'}
                      color={fontColor}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                    >
                      state
                    </Text>
                  </Box>
                  <Text
                    color={fontColor1}
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {singleUserData?.state}
                  </Text>
                </Box>
              </Box>

              <Box flexBasis={'50%'} padding={'16px'}>
                <Box
                  flexBasis={'50%'}
                  padding={'0 16px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    margin={'8px 0'}
                  >
                    <Text
                      flexBasis={'35%'}
                      textTransform={'capitalize'}
                      color={fontColor}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                    >
                      age
                    </Text>
                    <Text
                      color={fontColor1}
                      fontWeight={'400'}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                    >
                      {singleUserData?.age}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    margin={'8px 0'}
                  >
                    <Text
                      flexBasis={'35%'}
                      textTransform={'capitalize'}
                      color={fontColor}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                    >
                      gender
                    </Text>
                    <Text
                      color={fontColor1}
                      fontWeight={'400'}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                    >
                      {singleUserData?.gender}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    margin={'8px 0'}
                  >
                    <Text
                      flexBasis={'35%'}
                      textTransform={'capitalize'}
                      color={fontColor}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                    >
                      race
                    </Text>
                    <Text
                      color={fontColor1}
                      fontWeight={'400'}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                    >
                      {singleUserData?.race}
                    </Text>
                  </Box>

                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    margin={'8px 0'}
                  >
                    {currentUser?.role === 'super_admin' ? (
                      <>
                        <Text
                          flexBasis={'35%'}
                          textTransform={'capitalize'}
                          color={fontColor}
                          fontWeight={'700'}
                          lineHeight={'20px'}
                        >
                          political
                        </Text>
                        <Text
                          color={fontColor1}
                          fontWeight={'400'}
                          fontSize={'14px'}
                          lineHeight={'20px'}
                        >
                          {singleUserData?.politicalPreferences}
                        </Text>
                      </>
                    ) : (
                      ''
                    )}
                  </Box>

                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    margin={'8px 0'}
                  >
                    <Text
                      flexBasis={'35%'}
                      textTransform={'capitalize'}
                      color={fontColor}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                    >
                      country
                    </Text>
                    <Text
                      color={fontColor1}
                      fontWeight={'400'}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                    >
                      {singleUserData?.country}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* App Activity Graph */}

          <Box
            border={'1px'}
            borderRadius={'12px'}
            borderColor={'#E7E7E7'}
            width={isLargerThan850 ? '48%' : '100%'}
          >
            <AppActivityBarGraph
              data={graphData?.appActivity?.data}
              // data={[
              //   { xAxis: 'Tue', value1: 0 },
              //   { xAxis: 'Wed', value1: 4 },
              //   { xAxis: 'Thu', value1: 0 },
              //   { xAxis: 'Fri', value1: 8 },
              //   { xAxis: 'Sat', value1: 0},
              //   { xAxis: 'Sun', value1: 10 },
              //   { xAxis: 'Mon', value1: 0 },
              // ]}
              period={periods?.appActivity}
              onPeriodChange={(newPeriod) =>
                handlePeriodChange('appActivity', newPeriod)
              }
              periodOptions={periodOptions}
              graphData={graphData?.appActivity}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              pageName={'people'}
              currentUser={currentUser}
            />
          </Box>
        </Flex>

        {/* EngagementBarGraph  */}
        <Box width={'100%'} marginTop={'40px'}>
          <Text fontSize={'20px'} fontWeight={'bold'} ml={'6px'} mb={'12px'}>
            Engagement
          </Text>
          <Flex
            width={'100%'}
            gap={isLargerThan850 ? '2%' : '5%'}
            flexDirection={isLargerThan850 ? 'row' : 'column'}
          >
            <Box
              width={isLargerThan850 ? '48%' : '100%'}
              mb={isLargerThan850 ? '' : '19px'}
            >
              <PostNumberBarGraph
                data={
                  graphData?.postNumber?.data
                  // postNumberData
                }
                graphData={graphData?.postNumber}
                period={periods?.postNumber}
                onPeriodChange={(newPeriod) =>
                  handlePeriodChange('postNumber', newPeriod)
                }
                periodOptions={periodOptions}
                barLables={graphData?.postNumber?.labels}
                chipsPercentage={graphData?.postNumber?.percentage}
              />
            </Box>
            <Box width={isLargerThan850 ? '48%' : '100%'}>
              <ActivityTimeBarGraph
                data={
                  graphData?.activityTime?.times
                  // ActivityTimeBarGraphData
                }
                period={periods?.activityTime}
                onPeriodChange={(newPeriod) =>
                  handlePeriodChange('activityTime', newPeriod)
                }
                periodOptions={periodOptions}
                barLables={graphData?.activityTime?.labels}
                graphData={graphData?.activityTime}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                pageName={'people'}
                currentUser={currentUser}
              />
            </Box>
          </Flex>
        </Box>
        <Box width={'100%'}>
          <Flex width={'100%'} marginTop={'20px'}>
            <Box width={isLargerThan850 ? '65%' : '100%'}>
              <EngagementCategories
                categorieData={graphData?.categories?.categories}
                topicsData={topicsData}
                pageName={'people'}
                topicsBubbleData={topicsBubbleData}
                periodOptions={periodOptions}
                period={periods.categories}
                onPeriodChange={(newPeriod) =>
                  handlePeriodChange('categories', newPeriod)
                }
              />
            </Box>
          </Flex>
        </Box>
        {/* AwarenessBarGraph  */}
        <Box width={'100%'} marginTop={'40px'}>
          <Text fontSize={'20px'} fontWeight={'bold'} ml={'6px'} mb={'12px'}>
            Awareness
          </Text>
          <Flex
            width={'100%'}
            gap={isLargerThan850 ? '2%' : '5%'}
            flexDirection={isLargerThan850 ? 'row' : 'column'}
          >
            <Box
              width={isLargerThan850 ? '48%' : '100%'}
              mb={isLargerThan850 ? '' : '18px'}
            >
              <TimeSpendOnCheckInBarGraph
                data={
                  graphData?.timeSpendOnCheckIn?.checkInTime
                  // TimeSpendOnCheckInBarGraphData
                }
                period={periods?.timeSpendOnCheckIn}
                onPeriodChange={(newPeriod) =>
                  handlePeriodChange('timeSpendOnCheckIn', newPeriod)
                }
                periodOptions={periodOptions}
                barLables={graphData?.timeSpendOnCheckIn?.labels}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                userInfo={userInfo}
                pageName={'people'}
                currentUser={currentUser}
              />
            </Box>
            <Box width={isLargerThan850 ? '48%' : '100%'}>
              <AwarnessDistributionBarGraph
                data={
                  graphData?.awarnessDistribution?.questions
                  // AwarnessDistributionBarGraphData
                }
                period={periods?.awarnessDistribution}
                onPeriodChange={(newPeriod) =>
                  handlePeriodChange('awarnessDistribution', newPeriod)
                }
                periodOptions={periodOptions}
                barLables={graphData?.awarnessDistribution?.labels}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                pageName={'people'}
                currentUser={currentUser}
              />
            </Box>
          </Flex>
        </Box>
        {/* EvaluationBarGraph  */}
        <Box width={'100%'} marginTop={'40px'}>
          <Text fontSize={'20px'} fontWeight={'bold'} ml={'6px'} mb={'12px'}>
            Evaluation
          </Text>
          <Flex
            width={'100%'}
            gap={isLargerThan850 ? '2%' : '5%'}
            flexDirection={isLargerThan850 ? 'row' : 'column'}
          >
            <Box
              width={isLargerThan850 ? '48%' : '100%'}
              mb={isLargerThan850 ? '' : '18px'}
            >
              <TimeSpendOnCloserLookBarGraph
                data={
                  graphData?.timeSpendOnCloserLook?.closerLookTime
                  // timeSpendOnCloserLookData
                }
                period={periods?.timeSpendOnCloserLook}
                onPeriodChange={(newPeriod) =>
                  handlePeriodChange('timeSpendOnCloserLook', newPeriod)
                }
                periodOptions={periodOptions}
                barLables={graphData?.timeSpendOnCloserLook?.labels}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                userInfo={userInfo}
                pageName={'people'}
                currentUser={currentUser}
              />
            </Box>
            <Box width={isLargerThan850 ? '48%' : '100%'}>
              <EvaluationSkillsBarGraph
                data={
                  graphData?.evaluationSkills?.questions
                  // EvaluationBarGraphData
                }
                period={periods?.evaluationSkills}
                onPeriodChange={(newPeriod) =>
                  handlePeriodChange('evaluationSkills', newPeriod)
                }
                periodOptions={periodOptions}
                barLables={graphData?.evaluationSkills?.labels}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                pageName={'people'}
                currentUser={currentUser}
              />
            </Box>
          </Flex>
        </Box>
        {/* ThinkingBarGraph  */}
        {peoplesThinkingBargraph && (
          <Box width={'100%'} marginTop={'40px'}>
            <Text fontSize={'20px'} fontWeight={'bold'} ml={'6px'} mb={'12px'}>
              Thinking
            </Text>
            <Flex
              width={'100%'}
              gap={isLargerThan850 ? '2%' : '5%'}
              flexDirection={isLargerThan850 ? 'row' : 'column'}
            >
              <Box
                width={isLargerThan850 ? '48%' : '100%'}
                mb={isLargerThan850 ? '' : '18px'}
              >
                <ThinkingBarGraph
                  data={
                    // graphData.thinking
                    ThinkingBarGraphData
                  }
                  period={periods?.thinking}
                  onPeriodChange={(newPeriod) =>
                    handlePeriodChange('thinking', newPeriod)
                  }
                  periodOptions={periodOptions}
                  barLables={graphData?.thinking?.labels}
                />
              </Box>
              <Box width="48%">{/* <ActivityTimeBarGraph /> */}</Box>
            </Flex>
          </Box>
        )}
      </Box>

      {/* PIE CHART GRAPH */}

      {/* <Box>
        <PieChart />
      </Box> */}

      {/* Single Page Info Personal Section END From Here */}
    </Box>
  );
};

export default PersonalInfo;
