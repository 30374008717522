import Analytics from 'analytics';
import segmentPlugin from 'analytics-plugin-segment';

const writeKey = process.env.REACT_APP_SEGMENT_API_KEY;
// analytics added.
const analytics = Analytics({
  app: 'youvet_dashboard',
  plugins: [
    segmentPlugin({
      writeKey: writeKey,
    }),
  ],
});

export default analytics;
