import React from 'react';
import { Box, Text, Flex, ChakraProvider, extendTheme } from '@chakra-ui/react';
import LineBarChart from './LineBarChart';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select';
import { getDisplayValue } from '../../utils/getDisplayName';
import ChipPercantege from '../ChipPercentage';

const theme = extendTheme({
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
});

const PostNumberBarGraph = ({
  data,
  period,
  onPeriodChange,
  graphData,
  barLables,
  periodOptions,
  chipsPercentage,
}) => {

  console.log('====================================');
  console.log('chipsPercentage ===>', chipsPercentage);
  console.log('====================================');

  return (
    <ChakraProvider theme={theme}>
      <Box
        bg="transparent"
        borderRadius="12px"
        overflow="hidden"
        border="1px solid #E7E7E7"
        paddingBottom={'3%'}
        height={'100%'}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={4}
          pb={4}
          pt={4}
          borderBottom={'1px solid #E7E7E7'}
        >
          <Box>
            <Text fontSize="18px" fontWeight="600" color="#101828">
              Posts number
            </Text>
            <Text fontSize="14px" color="#475467" mt={1}>
              # of posts reviewed across social media platforms
            </Text>
          </Box>
          <Select onValueChange={onPeriodChange}>
            <SelectTrigger className="w-[150px]">
              <SelectValue
                placeholder={getDisplayValue(period, periodOptions)}
              />
            </SelectTrigger>
            <SelectContent>
              {periodOptions?.map((elem, index) => (
                <SelectItem value={elem?.value} key={index}>
                  {elem?.display}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          px={4}
          mt={'8px'}
          mb={8}
        >
          <Flex alignItems="baseline">
            <Text fontSize="20px" fontWeight="600" color="#101828">
              {graphData?.totalPosts}
            </Text>
            <Text fontSize="14px" color="#475467" ml={2}>
              posts
            </Text>
          </Flex>
          <Flex marginLeft={'5'} alignItems={'center'}>
            {chipsPercentage?.map((platformData, index) => (
              <ChipPercantege name={platformData?.name} percentage={platformData?.percentage} index={index} key={index} />
            ))}
          </Flex>
        </Flex>
        <LineBarChart
          data={data}
          isBarBackground={true}
          barLables={barLables}
          name={'postNumberGraph'}
        />
      </Box>
    </ChakraProvider>
  );
};

// Helper function to determine legend dot color
const getLegendDotColor = (index) => {
  const colors = ['#FA8638', '#676666', '#FFCC95']; // Define your colors here
  return colors[index % colors.length]; // Cycle through colors based on index
};

export default PostNumberBarGraph;
