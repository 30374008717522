import Analytics from 'analytics'
import segmentPlugin from 'analytics-plugin-segment'

const analytics = Analytics({
  app: 'youvet_dashboard',
  plugins: [
    segmentPlugin({
      writeKey: 'oT5iVpCSmsOIK6pnycyZyXqaDFUGa2zJ'
    })
  ]
})

export default analytics