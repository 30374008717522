import { Box, Flex, Spinner, Switch, Text, useToast } from '@chakra-ui/react';
import Twitter from 'assets/icons/Twitter';
import Instagram from 'assets/icons/Instagram';
import TikTok from 'assets/icons/TikTok';
import YouTube from 'assets/icons/YouTube';
import Facebook from 'assets/icons/Facebook';
import Linkedin from 'assets/icons/LinkedIn';
import Reddit from 'assets/icons/Reddit';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

let socialMediaList = [
  {
    name: 'X (formerly Twitter)',
    type: 1,
    icon: Twitter,
    nameCompare: 'Twitter',
  },
  {
    name: 'Instagram',
    type: 1,
    icon: Instagram,
    nameCompare: 'Instagram',
  },
  {
    name: 'Tik Tok',
    type: 1,
    icon: TikTok,
    nameCompare: 'TikTok',
  },
  {
    name: 'YouTube shorts',
    type: 1,
    icon: YouTube,
    nameCompare: 'YouTube',
  },
  {
    name: 'Facebook',
    type: 0,
    icon: Facebook,
    nameCompare: 'Facebook',
  },
  {
    name: 'LinkedIn',
    type: 0,
    icon: Linkedin,
    nameCompare: 'Linkedin',
  },
  {
    name: 'Reddit',
    type: 0,
    icon: Reddit,
    nameCompare: 'Reddit',
  },
];

const SocialMediaManagement = () => {
  const toast = useToast();
  const [switchStates, setSwitchStates] = useState({});
  const [socialMedia, setSocialMedia] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);

  const handleSwitchChange = async (id) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));
    const token = localStorage.getItem('token');
    const currentInstitution = JSON.parse(localStorage.getItem('institution'));
    if (!token || !currentInstitution) {
      console.error('Token or institution not found');
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    // Determine the current switch state
    const isSwitchOn = switchStates[id];

    try {
      const url = `${API_URL}/dashboard/institute/${
        isSwitchOn ? 'removePlatform' : 'addPlatform'
      }/${currentInstitution?._id}`;

      const body = { platform: id };
      let response;

      if (isSwitchOn) {
        response = await axios.patch(url, body, { headers });
      } else {
        response = await axios.post(url, body, { headers });
      }

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      // Update the switch state
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [id]: !isSwitchOn,
      }));

      toast({
        title: `Successful`,
        description: !isSwitchOn ? 'Platform Removed' : 'Platform Added',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
      //   console.log(`Switch ${id} is ${isSwitchOn ? 'OFF' : 'ON'}`);
    } catch (error) {
      console.error(
        'Error:',
        error.response ? error.response.data : error.message,
      );
      setSwitchStates((prevStates) => ({
        ...prevStates,
        [id]: !prevStates[id],
      }));
    } finally {
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
    }
  };

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const currentInstitution = JSON.parse(localStorage.getItem('institution'));
    if (!token || !currentInstitution) {
      console.error('Token or institution not found');
      return;
    }

    try {
      const url = `${API_URL}/dashboard/institute/getInstitutePlatforms?institution=${currentInstitution?._id}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSocialMedia(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [switchStates]);

  return (
    <Box width={'65%'} margin={'24px auto 48px'}>
      <Box>
        <Text
          textTransform={'capitalize'}
          fontSize={'20px'}
          fontWeight={'700'}
          lineHeight={'24px'}
        >
          Social media
        </Text>
        <Flex flexDir={'column'} margin={'10px 0'}>
          <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box flexBasis={'55%'}>
              <Text
                fontSize={'14px'}
                lineHeight={'20px'}
                fontWeight={'400'}
                color={'#757575'}
                pt={'4px'}
              >
                Manage networks that your students should view content from
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Box mt={'20px'}>
        {socialMediaList.length === 0 ? (
          <Text>No social media platforms available.</Text>
        ) : (
          <Flex flexDir={'column'} margin={'10px 0'}>
            {socialMediaList.map((media) => {
              const foundMedia = socialMedia.find(
                (item) => item.name === media.nameCompare,
              );

              // Check if foundMedia exists and get its disabled state
              const disabledState = foundMedia ? foundMedia.disabled : false;
              const _id = foundMedia ? foundMedia._id : null;
              const isLoading = loadingStates[_id] || false;
              //   console.log(
              //     `disabledState - ${media.nameCompare} - ${_id}`,
              //     disabledState,
              //   );
              return (
                <Flex
                  key={media.id}
                  flexDir={'column'}
                  paddingBottom={'12px'}
                  paddingTop={'12px'}
                  borderBottom={
                    media.name === 'Reddit' ? '0px' : '1px solid #E7E7E7'
                  }
                  opacity={media.type === 1 ? '1' : '0.3'}
                >
                  <Flex
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                  >
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'flex-start'}
                      opacity={!disabledState ? '1' : '0.3'}
                    >
                      {media.icon && (
                        <Box as={media.icon} fill={'#FFFDFB'} mr="8px" />
                      )}{' '}
                      {/* Render the icon */}
                      <Text
                        fontSize={'14px'}
                        lineHeight={'20px'}
                        fontWeight={'700'}
                        color={'#222222'}
                        pt={'4px'}
                        marginLeft="10px"
                      >
                        {media.name}
                      </Text>
                    </Flex>
                    <Box justifyContent="space-between" alignItems="center">
                      {isLoading ? (
                        <Flex
                          height={'22px'}
                          width={'44px'}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Spinner size={'sm'} color="#EF6506" />
                        </Flex>
                      ) : (
                        <Switch
                          id={_id}
                          isChecked={
                            (!disabledState && media.type === 1) || false
                          }
                          onChange={() => handleSwitchChange(_id)}
                          disabled={media.type === 1 ? false : true}
                          sx={{
                            '& .chakra-switch__track': {
                              backgroundColor: '#C2C2C2',
                            },
                            '& .chakra-switch__track[data-checked]': {
                              backgroundColor: '#EF6506',
                            },
                          }}
                        />
                      )}
                    </Box>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default SocialMediaManagement;
