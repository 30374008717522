import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  useToast,
  Icon,
  Input,
  useDisclosure,
  Flex,
  Box,
} from '@chakra-ui/react';
import { FiUpload, FiTrash2, FiEye } from 'react-icons/fi';
import { AiFillFileExcel } from 'react-icons/ai';
import axios from 'axios';
import './inviteModal.css';
import CSVModal from '../CsvModal/CsvModal';
import UploadCloudIcon from 'assets/icons/uploadCloudIcon-02';
const API_URL = process.env.REACT_APP_API_URL;

const ShareInviteModal = ({ onClose, isOpen, onSendInvite }) => {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);


  const toast = useToast();
  const {
    isOpen: isCsvModalOpen,
    onOpen: onCsvModalOpen,
    onClose: onCsvModalClose,
  } = useDisclosure();

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email.length) {
      const emails = email
        .toLowerCase()
        .split(',')
        .map((email) => email.trim().toLowerCase())
        .filter(Boolean);

      let isEmailValid = true;
      for (const emailId of emails) {
        isEmailValid = emailRegex.test(emailId);
        if (isEmailValid === false) {
          break;
        }
        setEmails(emails);
      }
      setIsValid(isEmailValid);
    }
  }, [email]);

  const handleCsvUpload = (e) => {
    setIsUploading(true);
    const file = e.target.files[0];
    if (file && file.type === 'text/csv') {
      setCsvFile(file);
      setIsUploading(false);
    } else {
      toast({
        title: 'Invalid file format',
        description: 'Please upload a valid CSV file.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsUploading(false);
    }
  };

  const handleDeleteCsv = () => {
    setCsvFile(null);
  };

    const handleDrop = (e) => {
      e.preventDefault();
      setIsUploading(true);
      const file = e.dataTransfer.files[0];
      if (file && file.type === 'text/csv') {
        setCsvFile(file);
        setIsUploading(false);
      } else {
        toast({
          title: 'Invalid file format',
          description: 'Please upload a valid CSV file.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsUploading(false);
      }
    };


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (email && csvFile) {
      toast({
        title: 'Invalid Invite',
        description: 'Please use one mode at a time.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const currentInstitution = JSON.parse(
        localStorage.getItem('institution'),
      );
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      let body = { institution: currentInstitution?._id };
      let apiPath = '';
      if (email) {
        body.emails = emails;
        apiPath = `inviteAppUser?invitationType=email`;
        setCsvFile(null);
      } else if (csvFile) {
        const formData = new FormData();
        formData.append('file', csvFile);
        formData.append('institution', currentInstitution?._id);
        body = formData;
        headers['Content-Type'] = 'multipart/form-data';
        apiPath = `inviteAppUserByFile`;
        setEmail('');
        setEmails([]);
      }

      const response = await axios.post(
        `${API_URL}/dashboard/users/${apiPath}`,
        body,
        { headers },
      );

      const { message } = response.data;

      toast({
        title: 'Invite Sent',
        description: message || 'Invitation has been sent successfully.',
        status: 'success',
        duration: 10000,
        isClosable: true,
      });

      onSendInvite({ email });
      setEmail('');
      setCsvFile(null);
      onClose();
    } catch (error) {
      console.log('invite error', error);
      toast({
        title: 'Error',
        description:
          error.response?.data?.message ||
          'An error occurred while sending the invite.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setCsvFile(null);
        onClose();
      }}
      size="md"
      isCentered
    >
      <ModalOverlay bg="#53535399" />
      <ModalContent
        bg={bgColor}
        borderColor={borderColor}
        borderWidth="1px"
        borderRadius={'12px'}
        alignSelf={'center'}
      >
        <ModalHeader paddingBottom={0}>Invite to Twella</ModalHeader>
        <ModalCloseButton style={{ marginTop: '8px' }} />
        <ModalBody paddingTop={0}>
          <Text fontSize="sm" color="gray.500" mb={'16px'}>
            Invite new users via email
          </Text>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel htmlFor="email" fontSize={'12px'} fontWeight={'700'}>
                  Email
                </FormLabel>
                <Textarea
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="email@example.com, email2@example.com,"
                  borderColor={'#AAAAAA'}
                  focusBorderColor="#EF6506"
                  _hover={{ border: ' 1px solid #EF6506' }}
                  borderRadius={'8px'}
                />
              </FormControl>
              <Flex width={'100%'} alignItems={'center'} margin={'24px 0'}>
                <Box
                  style={{
                    flexGrow: 1,
                    height: '0.5px',
                    border: '0.3px solid #AAAAAA',
                  }}
                ></Box>
                <Text
                  style={{
                    fontSize: '14',
                    color: '#46483C',
                    margin: '0px 16px 0px 16px',
                  }}
                  fontWeight={'400'}
                  lineHeight={'20px'}
                >
                  or
                </Text>
                <Box
                  style={{
                    flexGrow: 1,
                    height: '0.3px',
                    border: '0.5px solid #AAAAAA',
                  }}
                ></Box>
              </Flex>
              <FormControl>
                <FormLabel>Upload File (csv)</FormLabel>
                {!csvFile ? (
                  <VStack
                    border="1px solid"
                    borderColor={'#AAAAAA'}
                    padding={4}
                    align="center"
                    borderRadius="md"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    _hover={{ border: ' 1px solid #EF6506' }}
                  >
                    <Flex
                      justifyContent={'center'}
                      alignItems={'center'}
                      padding={'10px'}
                      border={'1px solid #EAECF0'}
                      bg={'#ffffff'}
                      borderRadius={'8px'}
                    >
                      <UploadCloudIcon />
                    </Flex>
                    <Text color="gray.500">
                      <label htmlFor="fileUpload">
                        <Text as="span" cursor="pointer" color="#EF6506">
                          {' '}
                          Click to upload{' '}
                        </Text>{' '}
                      </label>
                      or drag and drop
                    </Text>

                    <Input
                      type="file"
                      accept=".csv"
                      onChange={handleCsvUpload}
                      style={{ display: 'none' }}
                      id="fileUpload"
                    />

                    <Text as="span" color="#475467" fontSize={'12px'}>
                      Please ensure the file has names and emails
                    </Text>
                  </VStack>
                ) : (
                  <HStack
                    border="1px solid"
                    borderColor={'orange.400'}
                    padding={3}
                    align="center"
                    borderRadius="md"
                    justify="space-between"
                    w="full"
                  >
                    <HStack spacing={2}>
                      <Icon
                        as={AiFillFileExcel}
                        boxSize={6}
                        color="green.500"
                      />
                      <VStack align="start" spacing={0}>
                        <Text fontSize="sm" fontWeight="bold">
                          {csvFile.name}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          {(csvFile.size / 1024).toFixed(1)} KB - 100% uploaded
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack spacing={2}>
                      <Icon
                        as={FiEye}
                        color="blue.500"
                        cursor="pointer"
                        onClick={onCsvModalOpen}
                      />
                      <Icon
                        as={FiTrash2}
                        color="red.500"
                        cursor="pointer"
                        onClick={handleDeleteCsv}
                      />
                    </HStack>
                  </HStack>
                )}
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={3}>
            <Button
              variant="outline"
              onClick={() => {
                setCsvFile(null);
                onClose();
              }}
              borderRadius={'8px'}
              padding={'6px 12px'}
            >
              Cancel
            </Button>
            <Button
              bg={'#EF6506'}
              color={'white'}
              onClick={handleSubmit}
              borderRadius={'8px'}
              borderColor={'#AAAAAA'}
              height={'36px'}
              fontSize={'14px'}
              fontWeight={'700'}
              _hover={'none'}
              isLoading={isLoading}
              isDisabled={!isValid && !csvFile}
              padding={'6px 12px'}
            >
              Send Invite
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
      {/* Add the CSVPreviewModal */}
      <CSVModal
        isOpen={isCsvModalOpen}
        onClose={onCsvModalClose}
        csvFile={csvFile}
      />
    </Modal>
  );
};

export default ShareInviteModal;
